<!-- <nb-card>
  <nb-card-body>

    <table [nbTreeGrid]="data" equalColumnsWidth>

      <tr nbTreeGridRow *nbTreeGridRowDef="let row; columns: allColumns" class="table-row"></tr>

      <ng-container [nbTreeGridColumnDef]="customColumn">
        <td nbTreeGridCell *nbTreeGridCellDef="let row">

          <nb-tree-grid-row-toggle [expanded]="row.expanded" *ngIf="row.data.kind === 'dir'">
          </nb-tree-grid-row-toggle>
          {{row.data.name|translate}}
        </td>
      </ng-container>
      <ng-container *ngFor="let column of defaultColumns" [nbTreeGridColumnDef]="column">
        <td nbTreeGridCell *nbTreeGridCellDef="let row">{{row.data[column]}}</td>
      </ng-container>
    </table>
  </nb-card-body>
</nb-card> -->


<!-- <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    {{node.name|translate}}
  </mat-tree-node>
  <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
    <button mat-icon-button matTreeNodeToggle
            [attr.aria-label]="'Toggle ' + node.name">
      <mat-icon class="mat-icon-rtl-mirror">
        {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
      </mat-icon>
    </button>
    {{node.name|translate}}
  </mat-tree-node>
</mat-tree> -->
<!--SAMPLE CODE ENDS-->

<div class="aside d-flex flex-column" id="asideSidebar">
  <div class="aside-menu-wrapper">
    <div class="menuIcon" (click)="toggleMenu()">
      <i class="fa fa-bars"></i>
    </div>
    <div class="br-sideleft">
      <ul class="br-sideleft-menu">
        <li class="menu-link" routerLinkActive="active" *ngIf="enablePointDetail" [matTooltip]="'Point detail'|translate"
            matTooltipPosition="right" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/db/point-selection']">
          <a>
            <span class="icon">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve">
                <g id="graphical-data-flow_1_" transform="translate(-2.251 -2.25)">
                  <path id="Path_364_1_" d="M23.9,25.3h-4.8c-0.9,0-1.6-0.7-1.6-1.6v-4.8c0-0.9,0.7-1.6,1.6-1.6h4.8
                 c0.9,0,1.6,0.7,1.6,1.6v4.8C25.5,24.5,24.8,25.2,23.9,25.3z M19.1,18.8L19.1,18.8v4.8h4.8v-4.8H19.1z" />
                  <path id="Path_365_1_"
                    d="M15.9,22H11c-1.3,0-2.4-1.1-2.4-2.4v-3.2h1.6v3.2c0,0.4,0.4,0.8,0.8,0.8h4.8L15.9,22z" />
                  <path id="Path_366_1_"
                    d="M22.3,15.9h-1.6v-2.4c0-0.4-0.4-0.8-0.8-0.8h-4v-1.6h4c1.3,0,2.4,1.1,2.4,2.4V15.9z" />
                  <path id="Path_367_2_" d="M13.4,16.1c-0.1,0-0.3,0-0.4-0.1L9.4,14L5.8,16c-0.4,0.2-0.9,0.1-1.1-0.3
                 c-0.1-0.2-0.1-0.4-0.1-0.6l0.9-3.7L3.2,9c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.4-0.2L7,7.1l1.7-3.4c0.2-0.4,0.7-0.6,1.1-0.4
                 c0.2,0.1,0.3,0.2,0.4,0.4l1.8,3.4l3.3,0.6c0.4,0.1,0.7,0.5,0.7,0.9c0,0.2-0.1,0.3-0.2,0.4l-2.3,2.4l0.9,3.7c0.1,0.4-0.2,0.9-0.6,1
                 C13.6,16.1,13.5,16.1,13.4,16.1L13.4,16.1z M9.4,12.1c0,0,0.3,0.1,0.4,0.2l2.4,1.4L11.6,11l1.8-2l-2.6-0.4L9.4,5.9L8.1,8.6L5.4,9
                 l1.8,2l-0.6,2.7L9,12.3C9.1,12.3,9.4,12.1,9.4,12.1z" />
                </g>
              </svg>
            </span>
            <span class="menuItem"><strong>{{'Point detail'|translate}}</strong></span>
          </a>
        </li>

        <!-- ↓初回リリース時はこのブロックはコメントアウト -->
        <li class="menu-link" [matTooltip]="'Point Map'|translate" matTooltipPosition="right" routerLinkActive="active" *ngIf="enableMap"
            [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/db/map']">
          <a>
            <span class="icon">
              <mat-icon>map</mat-icon>
            </span>
            <span class="menuItem"><strong>{{'Point Map'|translate}}</strong></span>
          </a>
        </li>
        <!-- ↑初回リリース時はこのブロックはコメントアウト -->

        <!-- ↓初回リリース時はこのブロックはコメントアウト 20240423 濁度地図 -->
        <li class="menu-link" [matTooltip]="'Turbidity map'|translate" matTooltipPosition="right" routerLinkActive="active" *ngIf="enableTurbidityMap"
            [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/db/map-turbidity']">
          <a>
            <span class="icon">
              <mat-icon>map</mat-icon>
            </span>
            <span class="menuItem"><strong>{{'Turbidity map'|translate}}</strong></span>
          </a>
        </li>
        <!-- ↑初回リリース時はこのブロックはコメントアウト 20240423 濁度地図 -->

        <!-- 20240417追加 TODO:アイコン変更 -->
        <li class="menu-link" [matTooltip]="'Point detail (Turbidity)'|translate" matTooltipPosition="right" routerLinkActive="active" *ngIf="enableTurbidity"
          [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/db/point-selection-turbidity']">
          <a>
            <span class="icon">
              <!-- svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve">
                <g id="graphical-data-flow_1_" transform="translate(-2.251 -2.25)">
                  <path id="Path_364_1_" d="M23.9,25.3h-4.8c-0.9,0-1.6-0.7-1.6-1.6v-4.8c0-0.9,0.7-1.6,1.6-1.6h4.8
                 c0.9,0,1.6,0.7,1.6,1.6v4.8C25.5,24.5,24.8,25.2,23.9,25.3z M19.1,18.8L19.1,18.8v4.8h4.8v-4.8H19.1z" />
                  <path id="Path_365_1_"
                    d="M15.9,22H11c-1.3,0-2.4-1.1-2.4-2.4v-3.2h1.6v3.2c0,0.4,0.4,0.8,0.8,0.8h4.8L15.9,22z" />
                  <path id="Path_366_1_"
                    d="M22.3,15.9h-1.6v-2.4c0-0.4-0.4-0.8-0.8-0.8h-4v-1.6h4c1.3,0,2.4,1.1,2.4,2.4V15.9z" />
                  <path id="Path_367_2_" d="M13.4,16.1c-0.1,0-0.3,0-0.4-0.1L9.4,14L5.8,16c-0.4,0.2-0.9,0.1-1.1-0.3
                 c-0.1-0.2-0.1-0.4-0.1-0.6l0.9-3.7L3.2,9c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.4-0.2L7,7.1l1.7-3.4c0.2-0.4,0.7-0.6,1.1-0.4
                 c0.2,0.1,0.3,0.2,0.4,0.4l1.8,3.4l3.3,0.6c0.4,0.1,0.7,0.5,0.7,0.9c0,0.2-0.1,0.3-0.2,0.4l-2.3,2.4l0.9,3.7c0.1,0.4-0.2,0.9-0.6,1
                 C13.6,16.1,13.5,16.1,13.4,16.1L13.4,16.1z M9.4,12.1c0,0,0.3,0.1,0.4,0.2l2.4,1.4L11.6,11l1.8-2l-2.6-0.4L9.4,5.9L8.1,8.6L5.4,9
                 l1.8,2l-0.6,2.7L9,12.3C9.1,12.3,9.4,12.1,9.4,12.1z" />
                </g>
              </svg -->
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                <path d="M300-360q17 0 28.5-11.5T340-400q0-17-11.5-28.5T300-440q-17 0-28.5 11.5T260-400q0 17 11.5 28.5T300-360Zm60-120q17 0 28.5-11.5T400-520q0-17-11.5-28.5T360-560q-17 0-28.5 11.5T320-520q0 17 11.5 28.5T360-480Zm0 240q17 0 28.5-11.5T400-280q0-17-11.5-28.5T360-320q-17 0-28.5 11.5T320-280q0 17 11.5 28.5T360-240Zm60-120q17 0 28.5-11.5T460-400q0-17-11.5-28.5T420-440q-17 0-28.5 11.5T380-400q0 17 11.5 28.5T420-360Zm0-240q17 0 28.5-11.5T460-640q0-17-11.5-28.5T420-680q-17 0-28.5 11.5T380-640q0 17 11.5 28.5T420-600Zm60 120q17 0 28.5-11.5T520-520q0-17-11.5-28.5T480-560q-17 0-28.5 11.5T440-520q0 17 11.5 28.5T480-480Zm0 240q17 0 28.5-11.5T520-280q0-17-11.5-28.5T480-320q-17 0-28.5 11.5T440-280q0 17 11.5 28.5T480-240Zm60-120q17 0 28.5-11.5T580-400q0-17-11.5-28.5T540-440q-17 0-28.5 11.5T500-400q0 17 11.5 28.5T540-360Zm0-240q17 0 28.5-11.5T580-640q0-17-11.5-28.5T540-680q-17 0-28.5 11.5T500-640q0 17 11.5 28.5T540-600Zm60 120q17 0 28.5-11.5T640-520q0-17-11.5-28.5T600-560q-17 0-28.5 11.5T560-520q0 17 11.5 28.5T600-480Zm0 240q17 0 28.5-11.5T640-280q0-17-11.5-28.5T600-320q-17 0-28.5 11.5T560-280q0 17 11.5 28.5T600-240Zm60-120q17 0 28.5-11.5T700-400q0-17-11.5-28.5T660-440q-17 0-28.5 11.5T620-400q0 17 11.5 28.5T660-360ZM480-80q-137 0-228.5-94T160-408q0-100 79.5-217.5T480-880q161 137 240.5 254.5T800-408q0 140-91.5 234T480-80Zm0-80q104 0 172-70.5T720-408q0-73-60.5-165T480-774Q361-665 300.5-573T240-408q0 107 68 177.5T480-160Zm0-320Z"/>
              </svg>
            </span>
            <span class="menuItem"><strong>{{'Point detail (Turbidity)'|translate}}</strong></span>
          </a>
        </li>
        <!-- 20240417追加終了 -->

        <li class="menu-link" [matTooltip]="'Alert list'|translate" matTooltipPosition="right" routerLinkActive="active"
            [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/db/alarm-summary']">
          <a>
            <span class="icon">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24" xml:space="preserve">
                <g>
                  <path id="Icon_ionic-md-alarm_1_" d="M23,5.5l-5-4.3l-1.5,1.7l5,4.3L23,5.5z M7.5,2.7L6.1,0.9L1,5.1l1.4,1.7L7.5,2.7z
              M12.5,7.8l-1.6,0l-0.1,6.6l5.2,3.2l0.8-1.4l-4.4-2.6L12.5,7.8L12.5,7.8z M12,3.4c-5.4-0.1-9.9,4.3-9.9,9.7
             c-0.1,5.4,4.3,9.9,9.7,9.9c5.4,0.1,9.8-4.3,9.9-9.7C21.8,7.9,17.5,3.5,12,3.4C12,3.4,12,3.4,12,3.4z M11.7,20.9
             c-4.2-0.1-7.6-3.6-7.5-7.8s3.6-7.6,7.8-7.5c4.2,0.1,7.5,3.6,7.5,7.8C19.5,17.6,16,20.9,11.7,20.9z" />
                </g>
              </svg>
            </span>
            <span class="menuItem"><strong>{{'Alert list'|translate}}</strong></span>
          </a>
        </li>
        <!-- <li class="menu-link"  matTooltip="Messages" matTooltipPosition="right">
          <a>
            <span class="icon">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                style="enable-background:new 0 0 24 24;" xml:space="preserve">
                <path id="Icon_feather-message-square_1_" d="M22,15.3c0,1.2-1,2.2-2.2,2.2H6.4L2,22V4.2C2,3,3,2,4.2,2h15.5
                  C21,2,22,3,22,4.2L22,15.3z" />
              </svg>
            </span>
            <span class="menuItem">{{'Messages'|translate}}</span>
          </a>
        </li>  -->

        <!-- SAMPLE CODE STARTS -->
        <!-- <li class="menu-link" [matTooltip]="'Maintenance Settings'|translate" matTooltipPosition="right"
          routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/dashboard/settings']">
          <a>
            <span class="icon">
              <svg viewBox="0 -53 384 384" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                <path
                  d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
                <path
                  d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
              </svg>
            </span>
            <span class="menuItem">{{'Settings'|translate}}</span>
          </a>
        </li> -->
        <!-- <li class="menu-link" [matTooltip]="'Report'|translate" matTooltipPosition="right" routerLinkActive="active"
          [routerLink]="['/db/sensor-reports']">
          <a>
            <span class="icon">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                style="enable-background:new 0 0 24 24;" xml:space="preserve">
                <g id="report_1_" transform="translate(-5.625 -2.25)">
                  <path id="Path_359_1_" class="st0" d="M12.9,15.8h6.3v1.6h-6.3V15.8z" />
                  <path id="Path_360_1_" class="st0" d="M12.9,11.9h9.4v1.6h-9.4V11.9z" />
                  <path id="Path_361_1_" class="st0" d="M12.9,19.8h3.9v1.6h-3.9V19.8z" />
                  <path id="Path_362_1_" class="st0"
                    d="M24.7,5.6h-2.4V4.8c0-0.9-0.7-1.6-1.6-1.6c0,0,0,0,0,0h-6.3c-0.9,0-1.6,0.7-1.6,1.6
                    c0,0,0,0,0,0v0.8h-2.4C9.7,5.6,9,6.3,9,7.2c0,0,0,0,0,0v16.5c0,0.9,0.7,1.6,1.6,1.6l0,0h14.1c0.9,0,1.6-0.7,1.6-1.6l0,0V7.2
                    C26.3,6.3,25.6,5.6,24.7,5.6L24.7,5.6z M14.5,4.8h6.3V8h-6.3V4.8z M24.7,23.7H10.6V7.2h2.4v2.4h9.4V7.2h2.4L24.7,23.7z" />
                </g>
              </svg>
            </span>
            <span class="menuItem"><strong>{{'Report'|translate}}</strong></span>
          </a>
        </li> -->

        <li class="menu-link" [matTooltip]="'Point management'|translate" matTooltipPosition="right" routerLinkActive="active" *ngIf="isNSCAdmin"
          [routerLink]="['/db/point-management']">
          <a>
            <span class="icon">
              <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                <g>
                  <path d="M12,11.5A2.5,2.5 0 0,1 9.5,9A2.5,2.5 0 0,1 12,6.5A2.5,2.5 0 0,1 14.5,9A2.5,2.5 0 0,1 12,11.5M12,2A7,7 0 0,0 5,9C5,14.25 12,22 12,22C12,22 19,14.25 19,9A7,7 0 0,0 12,2Z" />
                </g>
              </svg>
            </span>
            <span class="menuItem"><strong>{{'Point management'|translate}}</strong></span>
          </a>
        </li>

        <li class="menu-link" [matTooltip]="'User management'|translate" matTooltipPosition="right" routerLinkActive="active"
            [routerLink]="['/db/user-management']">
          <a>
            <span class="icon">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 24 24"
                  style="enable-background:new 0 0 24 24;" xml:space="preserve">
                <g id="icon-u-manage" transform="translate(-0.625 -2.25)">
                  <path id="u-manage" class="st0"
                  d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,
                     1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,
                     13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,
                     13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,
                     14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,
                     13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,
                     13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,
                     0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,
                     3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z"/>
                </g>
              </svg>
            </span>
            <span class="menuItem"><strong>{{'User management'|translate}}</strong></span>
          </a>
        </li>
        <!-- SAMPLE CODE ENDS -->
      </ul>
    </div>
  </div>
</div>
