<!-- <h1 class="title">{{'Graph' | translate}}</h1> -->


<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="loaderDiv" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <div class="filterDate">
              <div class="datePicker">
                <div class="sensorData">
                  <!-- 20240417*ngIf="showStandardGraph"追加  -->
                  <!-- form [formGroup]="dataFormGroup" (ngSubmit)="dateFilter()" class="form-filter-date" *ngIf="showStandardGraph">
                    <div class="dropdwnInput">
                      <!-- <input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime"
                             formControlName="fromdatetime" [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime"
                             [(ngModel)]="fdtime" placeholder="{{'From Date' | translate}}" readonly> -->
                      <!-- input #fromdatetime id="fromdatetime" name="fromdatetime" class="fromdatetime" formControlName="fromdatetime"
                        [owlDateTimeTrigger]="fromDatTime" [owlDateTime]="fromDatTime"                 placeholder="{{'From Date' | translate}}" readonly />
                      <owl-date-time #fromDatTime pickerType="calendar"></owl-date-time>
                    </div>

                    <div class="dropdwnInput">
                      <!-- <input #todatetime id="todatetime" name="todatetime" class="todatetime"
                             formControlName="todatetime" [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"
                             [(ngModel)]="tdtime"
                             placeholder="{{'To Date'| translate}}" readonly> -->
                      <!-- input #todatetime id="todatetime" name="todatetime" class="todatetime" formControlName="todatetime"
                        [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"
                        placeholder="{{'To Date'| translate}}" readonly />
                      <owl-date-time #toDateTime pickerType="calendar"></owl-date-time>
                    </div>

                    <button class="submitBtn" type="submit" [disabled]="isSensor">{{'Submit' | translate}}</button>
                    <button class="clearBtn" type="reset" (click)="dateFilterClear()" [disabled]="isSensor">{{'Clear' | translate}}</button>
                  </form -->

                  <!-- 20240415追加 style="display: none;" -->
                  <form [formGroup]="dataFormGroupTurbidity" (ngSubmit)="dateFilterTurbidity()" class="form-filter-date">
                    <div class="dropdwnInput">
                      <!-- <input #todatetime id="todatetime" name="todatetime" class="todatetime"
                             formControlName="todatetime" [owlDateTimeTrigger]="toDateTime" [owlDateTime]="toDateTime"
                             [(ngModel)]="tdtime"
                             placeholder="{{'To Date'| translate}}" readonly> -->
                      <input #datetime id="datetime" name="datetime" class="datetime" formControlName="datetime"
                        [owlDateTimeTrigger]="DateTime" [owlDateTime]="DateTime"
                        placeholder="{{'Date & Time' | translate}}" readonly />
                      <owl-date-time #DateTime pickerType="both"></owl-date-time>
                    </div>

                    <!-- 20240510 文字数の関係で'Select date and time' の英訳は'Submit'とする-->
                    <button class="submitBtn" type="submit" [disabled]="isSensor">{{'Select date and time' | translate}}</button>
                    <button class="clearBtn" type="reset" (click)="dateFilterClearTurbidity()" [disabled]="isSensor">{{'Clear' | translate}}</button>
                  </form>
                  <!-- 20240415追加終了 -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<router-outlet></router-outlet>
<ngx-spinner bdColor="rgba(51,51,51,0.6)" size="large" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{'Loading'}}</p>
</ngx-spinner>


<!-- 20240417*ngIf="showStandardGraph"追加  -->
<!-- div class="selectdialog" *ngIf="showStandardGraph">
  <dialog id="dialog" class="dialog">
    <ul class="displayelement">
      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="selectall" [checked]="isAllSelect" (change)="selectAllGraph()" /> {{'Select all' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="waterquantity" [checked]="isWaterQuantitySelect" (change)="selectDataGraph()" /> {{'Water quantity' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="waterlevel" [checked]="isWaterLevelSelect" (change)="selectDataGraph()" /> {{'Water level' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="freshwaterconductivity" [checked]="isFreshwaterConductivitySelect" (change)="selectDataGraph()" /> {{'Freshwater conductivity EC25' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="saltwaterconductivity" [checked]="isSaltwaterConductivitySelect" (change)="selectDataGraph()" /> {{'Saltwater conductivity EC25' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="watertemperature" [checked]="isWaterTemperatureSelect" (change)="selectDataGraph()" /> {{'Water temprature' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="batteryvoltage" [checked]="isBatteryVoltageSelect" (change)="selectDataGraph()" /> {{'Battery voltage' | translate}}
        </a>
      </li>

      <li>
        <a href="javascript:void(0)">
          <input type="checkbox" id="turbidity" [checked]="isTurbiditySelect" (change)="selectDataGraph()" /> {{'Turbidity' | translate}}
        </a>
      </li>
    </ul>
    <div class="okbtn">
      <button (click)="clickOKBtn()" class="okbtndesign">{{'OK' | translate}}</button>
    </div>
  </dialog>
</div -->


<!-- 20240417*ngIf="showStandardGraph"追加  -->
<!-- div class="legend" *ngIf="showStandardGraph">
  <img src="../../../../../assets/graph.component.img/waterquantitylabel.png"
       alt="表示できるラベルがありません" class="wqlabel" *ngIf="isWaterQuantitySelect">
  <p class="textspace" *ngIf="isWaterQuantitySelect">{{'Water quantity' | translate}}(㎥/sec)</p>
  <img src="../../../../../assets/graph.component.img/waterlevellabel.png"
       alt="表示できるラベルがありません" class="wllabel" *ngIf="isWaterLevelSelect">
  <p class="textspace" *ngIf="isWaterLevelSelect">{{'Water level' | translate}}(m)</p>
  <img src="../../../../../assets/graph.component.img/freshwaterconductivitylabel.png"
       alt="表示できるラベルがありません" class="fwclabel" *ngIf="isFreshwaterConductivitySelect">
  <p class="textspace" *ngIf="isFreshwaterConductivitySelect">{{'Freshwater conductivity EC25' | translate}}(μS/cm)</p>
  <img src="../../../../../assets/graph.component.img/seawaterconductivitylabel.png"
       alt="表示できるラベルがありません" class="swclabel" *ngIf="isSaltwaterConductivitySelect">
  <p class="textspace" *ngIf="isSaltwaterConductivitySelect">{{'Saltwater conductivity EC25' | translate}}(mS/cm)</p>
  <img src="../../../../../assets/graph.component.img/batteryvoltagelabel.png"
       alt="表示できるラベルがありません" class="bvlabel" *ngIf="isBatteryVoltageSelect">
  <p class="textspace" *ngIf="isBatteryVoltageSelect">{{'Battery voltage' | translate}}(V)</p>
  <img src="../../../../../assets/graph.component.img/watertemperaturelabel.png"
       alt="表示できるラベルがありません" class="wtlabel" *ngIf="isWaterTemperatureSelect">
  <p class="textspace" *ngIf="isWaterTemperatureSelect">{{'Water temprature' | translate}}(℃)</p>
  <img src="../../../../../assets/graph.component.img/turbiditylabel.png"
       alt="表示できるラベルがありません" class="tlabel" *ngIf="isTurbiditySelect">
  <p class="textspace" *ngIf="isTurbiditySelect">{{'Turbidity' | translate}}(FTU)</p>
</div -->


<!-- 20240417*ngIf="showStandardGraph"追加  -->
<!-- button (click)="selectGraph()" class="opendialogbtn" *ngIf="showStandardGraph">
  <!--<mat-icon>settings</mat-icon>--> <!--データ選択の画像差し替え svgに変換し、svg内部の記述をコピペして対応。xmlns属性があればimgでもいける"らしい"-->
  <!--<img src="./src/assets/settings2.svg">-->
  <!-- svg class="settings2" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
    <g>
      <!--<path d="M0,0h24v24H0V0z" fill="none" />
      <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />-->
      <!-- path d="M7,5H21V7H7V5M7,13V11H21V13H7M4,4.5A1.5,1.5 0 0,1 5.5,6A1.5,1.5 0 0,1 4,7.5A1.5,1.5 0 0,1 2.5,6A1.5,1.5 0 0,1 4,4.5M4,10.5A1.5,1.5 0 0,1 5.5,12A1.5,1.5 0 0,1 4,13.5A1.5,1.5 0 0,1 2.5,12A1.5,1.5 0 0,1 4,10.5M7,19V17H21V19H7M4,16.5A1.5,1.5 0 0,1 5.5,18A1.5,1.5 0 0,1 4,19.5A1.5,1.5 0 0,1 2.5,18A1.5,1.5 0 0,1 4,16.5Z" />
  </g>
  </svg>
</button -->


<!-- <div class="table-responsive"> -->
  <!-- 20240417*ngIf="showStandardGraph"追加  -->
  <!-- div class="graphscroll" *ngIf="showStandardGraph">
    <div class="wrapperCanvas">
      <canvas #canvas01 id="chart" [width]="graphWidth" [height]="graphHeight"
              [datasets]="cookieService.get('language')=='en'?multipleLineChartData:multipleLineChartDataJP"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [plugins]="lineChartPlugins" baseChart
              chartType="line">
      </canvas>
    </div>
    <canvas id="yAxis" [width]="yAxisWidth" #canvas02 height="360" class="y"
            [datasets]="cookieService.get('language')=='en'?subLineChartData:subLineChartDataJP"
            [options]="subLineChartOptions"
            [plugins]="subLineChartPlugins"
            baseChart
            chartType="line">
    </canvas>
  </div -->
<!-- </div> -->

<!-- 240319追加 -->
<!--p></p-->
<div class="twographscroll">
  <div class="legendrainfall">
    <div class="textrainfallintensity">{{'Rainfall intensity' | translate}}</div>
    <div class="rainfalllabel">
      <img src="../../../../../assets/graph-turbidity.component.img/rainfallresultlabel.png"
         alt="表示できるラベルがありません" class="rrlabel">
      <div class="textspace">{{'Result' | translate}}</div>
      <img src="../../../../../assets/graph-turbidity.component.img/rainfallpredictedlabel.png"
         alt="表示できるラベルがありません" class="rplabel">
      <div class="textspace">{{'Prediction' | translate}}</div>
    </div>
  </div>
  <!-- 240319追加終了 -->

  <!-- 240311追加 -->
  <!-- <div class="table-responsive"> -->
  <div class="graphscroll">
    <div class="wrapperCanvas">
      <canvas #canvas01 id="chartRainfall" [width]="graphWidthRainfall" [height]="graphHeightRainfall"
              [datasets]="cookieService.get('language')=='en'?barChartData:barChartDataJP"
              [labels]="barChartLabels"
              [options]="bar1ChartOptions"
              [plugins]="lineChartPlugins" baseChart
              chartType="bar">
      </canvas>
    </div>
  </div>

  <div class="selectdialog">
    <dialog id="dialog" class="dialog">
      <ul class="displayelement">
        <li>
          <a href="javascript:void(0)">
            <input type="checkbox" id="selectall" [checked]="isAllSelect" (change)="selectAllGraph()" /> {{'Select all' | translate}}
          </a>
        </li>

        <li>
          <a href="javascript:void(0)">
            <input type="checkbox" id="turbidityObserved" [checked]="isTurbidityObservedSelect" (change)="selectDataGraph()" /> {{'Turbidity(observed)' | translate}}
          </a>
        </li>

        <li>
          <a href="javascript:void(0)">
            <input type="checkbox" id="turbidityMCMCMAP" [checked]="isTurbidityMCMCMAPSelect" (change)="selectDataGraph()" /> {{'Turbidity(MCMC-MAP)' | translate}}
          </a>
        </li>

        <li>
          <a href="javascript:void(0)">
            <input type="checkbox" id="turbidityAnalyzed" [checked]="isTurbidityAnalyzedSelect" (change)="selectDataGraph()" /> {{'Turbidity(analyzed)' | translate}}
          </a>
        </li>

        <li>
          <a href="javascript:void(0)">
            <input type="checkbox" id="waterQuantityEstimated" [checked]="isWaterQuantityEstimatedSelect" (change)="selectDataGraph()" /> {{'Water quantity(estimated)' | translate}}
          </a>
        </li>
      </ul>
      <div class="okbtn">
        <button (click)="clickOKBtn()" class="okbtndesign">{{'OK' | translate}}</button>
      </div>
    </dialog>
  </div>

  <button (click)="selectGraph()" class="opendialogbtn">
    <img src="./assets/Icons/openDialogBtn.svg">
  </button>

  <!--p></p-->
  <div class="legendturbidity">
    <img src="../../../../../assets/graph-turbidity.component.img/turbidity(observed)label.png"
         alt="表示できるラベルがありません" class="tolabel">
    <div class="textspace">{{'Turbidity(observed)' | translate}}</div>
    <img src="../../../../../assets/graph-turbidity.component.img/turbidity(MCMC-MAP)label.png"
         alt="表示できるラベルがありません" class="tmlabel">
    <div class="textspace">{{'Turbidity(MCMC-MAP)' | translate}}</div>
    <img src="../../../../../assets/graph-turbidity.component.img/turbidity(analyzed)label.png"
         alt="表示できるラベルがありません" class="talabel">
    <div class="textspace">{{'Turbidity(analyzed)' | translate}}</div>
    <img src="../../../../../assets/graph-turbidity.component.img/waterquantity(estimated)label.png"
         alt="表示できるラベルがありません" class="wqelabel">
    <div class="textspace">{{'Water quantity(estimated)' | translate}}</div>
  </div>

  <div class="graphscroll">
    <div class="wrapperCanvas">
      <canvas #canvas02 id="chartTurbidity" [width]="graphWidthTurbidity" [height]="graphHeightTurbidity"
              [datasets]="cookieService.get('language')=='en'?logChartData:logChartDataJP"
              [labels]="logChartLabels"
              [options]="logChartOptions"
              [plugins]="logChartPlugins" baseChart
              chartType="line">
      </canvas>
    </div>
  </div>
</div>
<!-- </div> -->
<!-- 240311追加終了 -->


<!-- 20240417*ngIf="showStandardGraph"追加  -->
<!-- div class="mat-elevation-z8" *ngIf="showStandardGraph">
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="sampleSource3" matSort class="example-class">

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="cb">
          <mat-checkbox (change)="isAllPointSelect($event.checked)" [checked]="headerCheckBox"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox (change)="checkedOrNot($event.checked, i, element.mainSensorId)" class="check"
            [checked]="element.checkedOrNot">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PointDetail">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="detailbtn"> {{'Detail' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="action-link">
          <button mat-raised-button color="primary" (click)="onClick(element.mainSensorId)">{{'Detail' | translate}}</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="cityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'City' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.cityName | translate}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="areaName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort('areaName')"> {{'Area' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.areaName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sensorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort('sensorName')"> {{'Point' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sensorName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Date & Time' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateTime}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterQuantity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Water quantity' | translate}}(㎥/sec) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterQuantity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterLevel">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Water level' | translate}}(m) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterLevel}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="freshwaterConductivity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Freshwater conductivity EC25' | translate}}(μS/cm) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.freshwaterConductivity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="saltwaterConductivity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Saltwater conductivity EC25' | translate}}(mS/cm) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.saltwaterConductivity}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="waterTemprature">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Water temprature' | translate}}(℃) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.waterTemprature}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="batteryVoltage">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Battery voltage' | translate}}(V) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.batteryVoltage}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidity">
        <mat-header-cell *matHeaderCellDef mat-sort-header> {{'Turbidity' | translate}}(FTU) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidity}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns2"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns2;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
  </div>
</div -->

<!-- 240408追加 -->
<!-- div class="mat-elevation-z8" style="display: none;" -->
<div class="mat-elevation-z8">
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="sampleSource4" matSort #sorter2="matSort" class="example-class">

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="cb">
          <!-- mat-checkbox (change)="isAllPointSelect($event.checked)" [checked]="headerCheckBox"></mat-checkbox -->
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox (change)="checkedOrNotTurbidity($event.checked, i, element.mainSensorId)" class="check"
            [checked]="element.checkedOrNot">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PointDetail">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="detailbtn"> {{'Detail' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="action-link">
          <button mat-raised-button color="primary" (click)="onClickTurbidity(element.mainSensorId)">{{'Detail' | translate}}</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="areaName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Area' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.areaName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sensorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Point' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sensorName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Date & Time' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateTime}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityNow">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Now' | translate}} ({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityNow}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityMax">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Maximum' | translate}} ({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityMax}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTimeMax">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Maximum arrival date and time' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateTimeMax}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict060">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(1h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict060}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict120">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(2h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict120}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict180">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(3h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict180}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict240">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(4h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict240}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict300">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(5h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict300}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict360">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(6h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict360}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict420">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(7h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict420}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict480">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(8h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict480}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict540">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(9h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict540}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict600">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(10h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict600}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict660">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(11h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict660}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict720">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(12h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict720}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict780">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(13h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict780}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict840">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(14h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict840}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict900">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}}<br>(15h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict900}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast060">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}}<br>(1h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast060}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast120">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}}<br>(2h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast120}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast180">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}}<br>(3h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast180}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast240">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}}<br>(4h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast240}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast300">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}}<br>(5h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast300}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast360">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}}<br>(6h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast360}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast420">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(7h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast420}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast480">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(8h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast480}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast540">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(9h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast540}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast600">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(10h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast600}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast660">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(11h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast660}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast720">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(12h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast720}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast780">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(13h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast780}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast840">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(14h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast840}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast900">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' |translate}}<br>(15h)(mm/h) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast900}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns3"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns3;"></mat-row>
    </mat-table>
    <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                   showFirstLastButtons [length]="sampleSource4Length"></mat-paginator>
  </div>
</div>
<!-- 240408追加終了 -->

<ng-snotify></ng-snotify>
