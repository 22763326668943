<!--SAMPLE CODE STARTS-->
<div class="subheader subheader-solid" id="kt_subheader">
  <div class="container-fluid">
    <div class="loaderDiv" *ngIf="loading">
      <mat-spinner></mat-spinner>
    </div>
    <div class="top-bar">
      <div class="dbWrapper">
        <div class="dbMenuStrip">
          <div class="dbLeftCol">
            <div class="filterDate">
              <div class="datePicker">
                <div class="sensorData">
                  <form [formGroup]="dataFormGroupTurbidity" (ngSubmit)="dateFilterTurbidity()" class="form-filter-date">
                    <div class="dropdwnInput">
                      <input #datetime id="datetime" name="datetime" class="datetime" formControlName="datetime"
                             [owlDateTimeTrigger]="DateTime" [owlDateTime]="DateTime"
                             placeholder="{{'Date & Time' | translate}}" readonly />
                      <owl-date-time #DateTime pickerType="both"></owl-date-time>
                    </div>

                    <!-- TODO: 多言語対応 -->
                    <button class="submitBtn" type="submit" [disabled]="isSensor">{{'Select date and time' | translate}}</button>
                    <button class="clearBtn" type="reset" (click)="dateFilterClearTurbidity()" [disabled]="isSensor">{{'Clear' | translate}}</button>
                  </form>
                  <!-- 20240415追加終了 -->

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--地図-->
<div class="mapFlow" style="height:60vh;">
  <google-map width="100%" height="60vh" [options]="options">
    <div style="position:absolute; top:135px; display:flex; ">
      <div id="mapTitle1"></div><div id="mapTitle2" translate></div><div id="mapTitle3"></div><div id="mapTitle4" translate></div>
    </div>
    <map-marker #somemarker="mapMarker" *ngFor="let mp of markers; let i = index"
                [position]="mp.position" [visible]="mp.visible" (mapClick)="openInfo(somemarker, markerInfos[i])">
      <map-info-window>
        <table border="5" id="markerInfo">
          <tbody>
            <tr><th colspan="21" id="markerth"></th></tr>
            <tr><td colspan="11" name="markertd">{{'Current Turbidity' | translate}}</td><td colspan="10" name="markertdr" id="markertdnow">236</td></tr>
            <tr><td colspan="11" name="markertd">{{'Predicted Max Turbidity' | translate}}</td><td colspan="10" name="markertdr" id="markertdmax">242</td></tr>
            <tr><td colspan="11" name="markertd">{{'Predicted Max Turbidity Time' | translate}}</td><td colspan="10" name="markertd" id="markertdmaxdate">testVal3</td></tr>
            <tr><td colspan="5" name="markertdh">{{'Special Attention' | translate}}</td><td name="markertda1" value="0"></td><td name="markertda1" value="1"></td><td name="markertda1" value="2"></td><td name="markertda1" value="3"></td><td name="markertda1" value="4"></td><td name="markertda1" value="5"></td><td name="markertda1" value="6"></td>
              <td name="markertda1" value="7"></td><td name="markertda1" value="8"></td><td name="markertda1" value="9"></td><td name="markertda1" value="10"></td><td name="markertda1" value="11"></td><td name="markertda1" value="12"></td><td name="markertda1" value="13"></td><td name="markertda1" value="14"></td><td name="markertda1" value="15"></td></tr>
            <tr><td colspan="5" name="markertdh">{{'Caution' | translate}}</td><td name="markertda2" value="0"></td><td name="markertda2" value="1"></td><td name="markertda2" value="2"></td><td name="markertda2" value="3"></td><td name="markertda2" value="4"></td><td name="markertda2" value="5"></td><td name="markertda2" value="6"></td>
              <td name="markertda2" value="7"></td><td name="markertda2" value="8"></td><td name="markertda2" value="9"></td><td name="markertda2" value="10"></td><td name="markertda2" value="11"></td><td name="markertda2" value="12"></td><td name="markertda2" value="13"></td><td name="markertda2" value="14"></td><td name="markertda2" value="15"></td></tr>
            <tr><td colspan="5" name="markertdh">{{'Normal' | translate}}</td><td name="markertda3" value="0"></td><td name="markertda3" value="1"></td><td name="markertda3" value="2"></td><td name="markertda3" value="3"></td><td name="markertda3" value="4"></td><td name="markertda3" value="5"></td><td name="markertda3" value="6"></td>
              <td name="markertda3" value="7"></td><td name="markertda3" value="8"></td><td name="markertda3" value="9"></td><td name="markertda3" value="10"></td><td name="markertda3" value="11"></td><td name="markertda3" value="12"></td><td name="markertda3" value="13"></td><td name="markertda3" value="14"></td><td name="markertda3" value="15"></td></tr>
            <tr>
              <td colspan="5" name="markertdh">{{'Elapsed Time' | translate}}</td><td name="markertdswitch">0</td><td name="markertdswitch">1</td><td name="markertdswitch">2</td><td name="markertdswitch">3</td><td name="markertdswitch">4</td><td name="markertdswitch">5</td><td name="markertdswitch">6</td>
              <td name="markertdswitch">7</td><td name="markertdswitch">8</td><td name="markertdswitch">9</td><td name="markertdswitch">10</td><td name="markertdswitch">11</td><td name="markertdswitch">12</td><td name="markertdswitch">13</td><td name="markertdswitch">14</td><td name="markertdswitch">15</td>
            </tr>
          </tbody>
        </table>

        <!--<strong>センサー情報</strong>-->
        <!--<div [innerHtml]="infoContent"></div>-->
        <!--<div style="margin-top: 1rem; width: 100%; text-align: right;">-->
        <!--<button mat-raised-button color="primary" (click)="jumpPointDetail(markerInfos[i].mainSensorId)">{{'Detail' | translate}}</button>-->
        <!--</div>-->
      </map-info-window>
    </map-marker>
    <map-ground-overlay id="mapRainfallImage" [url]="imageUrl" [bounds]="imageBounds"></map-ground-overlay>
    <div style="position: relative; pointer-events:none; font-size:60%; top:-100%; height:100%; display:flex; justify-content:flex-end; flex-direction:column;">
      <div id="legendImg">
        <div style="text-align:center; margin:0 0 -3px 0">{{'Live and forecast rainfall' | translate}}</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#FFC2FF"></span>130.1～</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#FF00FF"></span>～130</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#FF0000"></span>～100</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#FF4F00"></span>～70</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#FFAC00"></span>～50</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#FFFF00"></span>～30</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#8CE300"></span>～20</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#008CFF"></span>～10</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#00C2FF"></span>～5</div>
        <div style="margin:0 0 -8px 1px"><span name="legendColor" style="background-color:#8CFFFF"></span>0.1～1</div>
        <div style="text-align:right;">（mm/h）</div>
      </div>
    </div>
</google-map>
</div>

<!-- リスト -->
<div class="mat-elevation-z8">
  <div class="example-container mat-elevation-z8">
    <mat-table [dataSource]="sampleSource4" matSort #sorter="matSort" class="example-class">

      <!--<ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="cb">-->
          <!-- mat-checkbox (change)="isAllPointSelect($event.checked)" [checked]="headerCheckBox"></mat-checkbox -->
        <!--</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox (change)="checkedOrNotTurbidity($event.checked, i, element.mainSensorId)" class="check"
                        [checked]="element.checkedOrNot">
          </mat-checkbox>
        </mat-cell>
      </ng-container>-->
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="cb">
          <mat-checkbox (change)="isAllPointSelect($event.checked)" [checked]="headerCheckBox"></mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox (change)="checkedOrNot($event.checked, i, element.mainSensorId)" class="check"
                        [checked]="element.checkedOrNot">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="PointDetail">
        <mat-header-cell *matHeaderCellDef class="detailbtn"> {{'Detail' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index" class="action-link">
          <button mat-raised-button color="primary" (click)="onClickTurbidity(element.mainSensorId)">{{'Detail' | translate}}</button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="areaName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Area' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.areaName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sensorName">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Point' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.sensorName | translate}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Date & Time' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateTime}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityNow">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Now' | translate}} ({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityNow}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityMax">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Predicted Max Turbidity2' | translate}} <br> ({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityMax}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateTimeMax">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Predicted Max Turbidity Time' | translate}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.dateTimeMax}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict060">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (1h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict060}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict120">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (2h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict120}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict180">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (3h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict180}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict240">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (4h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict240}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict300">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (5h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict300}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict360">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (6h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict360}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict420">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (7h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict420}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict480">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (8h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict480}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict540">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (9h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict540}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict600">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (10h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict600}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict660">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (11h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict660}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict720">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (12h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict720}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict780">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (13h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict780}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict840">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (14h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict840}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="turbidityPredict900">
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Turbidity Predict' | translate}} <br> (15h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.turbidityPredict900}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast060">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (1h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast060}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast120">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (2h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast120}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast180">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (3h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast180}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast240">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (4h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast240}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast300">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (5h)({{turbidithUnit}} </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast300}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast360">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (6h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast360}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast420">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (7h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast420}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast480">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (8h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast480}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast540">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (9h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast540}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast600">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (10h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast600}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast660">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (11h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast660}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast720">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (12h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast720}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast780">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (13h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast780}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast840">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (14h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast840}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rainfallForecast900">
        <!-- TODO: 多言語対応 -->
        <mat-header-cell *matHeaderCellDef mat-sort-header (click)="mainSort()"> {{'Rainfall Forecast' | translate}} <br> (15h)({{turbidithUnit}}) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.rainfallForecast900}} </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns3"></mat-header-row>
      <mat-row *matRowDef="let element; columns: displayedColumns3;"></mat-row>
    </mat-table>
    <mat-paginator #paginator="matPaginator" [pageSizeOptions]="[5, 10, 25, 50, 100]"
                   showFirstLastButtons [length]="sampleSource4Length"></mat-paginator>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.6)" size="large" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">{{'Loading'}}</p>
</ngx-spinner>
