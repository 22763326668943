import { Component, OnInit, ViewChild} from '@angular/core';
import {Observable, Observer} from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { TodoService } from 'src/app/services/todo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import * as signalR from '@microsoft/signalr';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { MatSort, matSortAnimations } from '@angular/material/sort';
import { BaseChartDirective } from 'ng2-charts';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { SelectionModel } from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator} from '@angular/material/paginator';
import { Console } from 'console';
import { format } from 'path';
import * as _ from "lodash";
import {ExportToCsv} from 'export-to-csv-file';
import  jp  from 'src/assets/json/jp.json';
import  en  from 'src/assets/json/en.json';

export interface ExtraThresholdData{
  Datetime: string;
  City: string;
  Area: string;
  Point: string;
  Sensor: string;
  Unit: string;
  AlertLevel: number | string;
  ThresholdValue: number | string;
  Value: number | string;
}

//閾値超過はこちらを使う
export interface thresholdAlert{
  Datetime: string;
  City: string;
  Area: string;
  Point: string;
  Sensor: string;
  Unit: string;
  AlertLevel: number | string;
  ThresholdValue: number | string;
  Value: number | string;
}


export interface MachineIrregularData{
  Datetime: string;
  City: string;
  Area: string;
  Point: string;
  Sensor: string;
  Unit: string;
  ThresholdValue: number | string;
  Value: number | string;
  Detail: string;
}

@Component({
  selector: 'app-alarm',
  templateUrl: './alarm.component.html',
  styleUrls: ['./alarm.component.scss']
})

export class AlarmComponent implements OnInit {
  thresholdColumn = ['Datetime', 'City', 'Area', 'Point', 'Sensor', 'AlertLevel', 'ThresholdValue', 'RawValue'];
  columnName2 = ['Datetime', 'City', 'Area', 'Point', 'Sensor', 'ThresholdValue', 'RawValue', 'Detail'];
  @ViewChild('thresholdPaginator', {read: MatPaginator}) thresholdPaginator?: MatPaginator;
  @ViewChild('machineIrregularPaginator', {read: MatPaginator}) machineIrregularPaginator?: MatPaginator;
  @ViewChild('thresholdSort', {read: MatSort}) thresholdSortItem?: MatSort;
  @ViewChild('machineIrregularSort', {read: MatSort}) machineIrregularSortItem?: MatSort;
  //@ViewChild('thresholdSort') empTbSort = new MatSort();
  dataFormGroup = new FormGroup({});
  fdtime: any = [];
  tdtime: any = [];
  responseDataSource?: any;
  currentCityId: any;
  allThresholdValueResponse: any;
  extraThresholdDataList: ExtraThresholdData[] = [];
  thresholdAlertArray: thresholdAlert[] = []; //閾値超過はこちらを使う
  machineIrregularDataList: MachineIrregularData[] = [];
  waterLevelAlertLevel1: any = 0;
  waterLevelAlertLevel2: any = 0;
  waterLevelAlertLevel3: any = 0;
  waterQuantityAlertLevel1: any = 0;
  waterQuantityAlertLevel2: any = 0;
  waterQuantityAlertLevel3: any = 0;
  extraThresholdDataSource: any;
  machineIrregularDataSource: any;
  allAlertData: any;
  irregularThresholdValue: any;
  irregularValue: any;
  //searchedData:any;
  pointData: any;
  pickedPointData: any;
  dateFilteredData1: any;
  dateFilteredData2: any;
  selectDate: any;
  searchMode: boolean = false;
  mainSendData: any;
  machineIrregularSendData: any;
  // extraThresholdSendData: any;
  thresholdAlertSendData: any;
  currentView = 'extraThreshold';
  //waterLevelCorrectionValue: any;
  //waterQuantityCorrectionValue: any;
  perPage: number = 10;
  thresholdDataLength: any;
  machineIrregularDataLength: any;
  thresholdPagesize: any = [];
  machineIrregularPagesize: any = [];
  areasArray: any = [];//地区一覧
  currentArea: string = "";//現在選択中の地区
  sensorsArray: any = [];//センサー一覧
  currentSensor: string = "";//現在選択中のセンサー
  isDisablePointSelect: boolean = true;//地点を選択できるか
  copyAreasArray: any = [];
  copySensorsArray: any = [];
  isAreaSelected: boolean = false;//地区が選ばれているかどうか
  isSensorSelected: boolean = false;//地点が選ばれているかどうか
  isExportBtn: boolean = true;
  options: any;
  csvDataFormGruop = new FormGroup({});
  csvResponseData: any;
  csvPointData: any;
  csvAlertRequest: any;
  selectedAreaName: string = "";
  selectedPointName: string = "";
  defaultSensorVal: string = "Select";
  isCompleteProcess: boolean = false; //閾値超過または機器異常のデータ処理が完了したかを把握するフラグ

  isEnableTurbidity: boolean = false; //濁度オプション //20240520追加

  //dataFormGroup = new FormGroup({});
  constructor(private detailRouter: Router,
    public todoService: TodoService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService, public http: HttpClient,
    private router: Router, private toastr: ToastrService,
    public cookieService: CookieService, private snotifyService: SnotifyService,
    private translate: TranslateService,
    private datePipe: DatePipe,private dateTimeAdapter: DateTimeAdapter<any>){
      this.dataFormGroup = new FormGroup({
        fromdatetime: new FormControl(),
        todatetime: new FormControl()
      });
      this.datePickerlang()
    }

  ngOnInit(): void {
    //let japanese = jp;
    //let english = en;
    this.spinner.show();
    this.currentCityId = sessionStorage.getItem("cityId");
    this.isEnableTurbidity = (sessionStorage.getItem("enableTurbidity") === "true"); //20240520追加
    this.getDataByCityId();
    this.getThresholdValueByCityId();
    //this.getAlertListByCityId();
    this.getSensorNamesAndAreaNames();
  }

  datePickerlang(){
    const lang = sessionStorage.getItem("lang");

    this.todoService.languageData.subscribe((data: any) => {

      if(data == 'jp'){
        this.dateTimeAdapter.setLocale('ja-JP');
      }
      else if(data == 'en'){
       this.dateTimeAdapter.setLocale('en');
      }
      else{

        if(lang){

          if( lang == 'en'){
            this.dateTimeAdapter.setLocale('en');
          }

          if( lang == 'jp'){
            this.dateTimeAdapter.setLocale('ja-JP');
          }
        }
      }
    });
  }

  dateFilter(){
    this.spinner.show();

    // 日付入力チェック
    const data = {
      cityId: this.currentCityId,
      fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
      toDate: moment(new Date(this.dataFormGroup.value.todatetime)).format('YYYY-MM-DD')
    }
    if(data.fromDate == 'Invalid date' || data.toDate == 'Invalid date' || this.dataFormGroup.value.fromdatetime == null || this.dataFormGroup.value.todatetime == null){
      if(data.fromDate == 'Invalid date' || this.dataFormGroup.value.fromdatetime == null){
        alert("日付(From)が入力されていません。");
      }
      else if(data.toDate == 'Invalid date' || this.dataFormGroup.value.todatetime == null){
        alert("日付(To)が入力されていません。");
      }
      this.spinner.hide();
      return;
    }

    this.searchMode = true;
    this.getDataByCityId();

    if(this.currentView == 'extraThreshold'){
      this.getThresholdValueByCityId();
    }
    else{

      if(this.machineIrregularDataList.length > 0){
        this.machineIrregularDataList.length = 0;
      }
      this.getAlertListByCityId();
    }
  }

  dateFilterClear(): void{
    this.searchMode  = false;
    this.spinner.show();
    this.getDataByCityId();
    this.getThresholdValueByCityId();
    this.getAlertListByCityId();
  }

  tabChanged($event: any): void{
    //this.spinner.show();
    if($event.index == 0) this.currentView = 'extraThreshold';
    else this.currentView = 'machineIrregular';
    //this.spinner.hide();
  }

  //都市IDでその都市の全データを取得する
  getDataByCityId(): void{
    // const today = new Date();

    // //日付が指定されたときの送信データ
    // if(this.searchMode == true){

    //   this.mainSendData = {
    //     cityId: this.currentCityId,
    //     fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
    //     toDate: moment(new Date(this.dataFormGroup.value.todatetime)).format('YYYY-MM-DD')
    //   }

    //   //日付入力欄のチェック(リリース時コメントアウト)
    //   if(this.mainSendData.fromDate == 'Invalid date' || this.mainSendData.toDate == 'Invalid date'
    //    || this.dataFormGroup.value.fromdatetime == null || this.dataFormGroup.value.todatetime == null){

    //     if(this.mainSendData.fromDate == 'Invalid date' || this.dataFormGroup.value.fromdatetime == null){
    //       alert("日付(From)が入力されていません。");
    //     }
    //     else if(this.mainSendData.toDate == 'Invalid date' || this.dataFormGroup.value.todatetime == null){
    //       alert("日付(To)が入力されていません。");
    //     }
    //     this.spinner.hide();
    //     return;
    //   }
    // }

    // //日付が選択されていないときの送信データ
    // else{

    //   this.mainSendData = {
    //     cityId: this.currentCityId,
    //     fromDate: moment(new Date(today)).subtract(30, 'h').format("YYYY-MM-DD"),
    //     toDate: moment(new Date(today)).add(1, 'd').format("YYYY-MM-DD"),
    //   };
    // }

    // this.todoService.getAllByCityIdAndMultipleDates(this.mainSendData).subscribe((response: any) => {
    //   this.responseDataSource = response;
    //   //this.makeExtraThresholdData();
    //   this.getAlertListByCityId();
    //   this.showThresholdAlerts(); //新規追加
    // })

    this.getAlertListByCityId();
    this.showThresholdAlerts(); //新規追加
    //this.makeExtraThresholdData();
  }

  //都市IDでその都市の閾値設定を取得する
  getThresholdValueByCityId(): void{

    this.todoService.getThresholdValuesByCityId(this.currentCityId).subscribe((response: any) => {
      this.allThresholdValueResponse = response;
      //this.makeExtraThresholdData();
      //this.showThresholdAlerts(); //新規追加
      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      this.searchMode  = false;
    });
    //this.makeExtraThresholdData();
  }

  //都市IDでその都市の機器異常を全て取得する
  getAlertListByCityId(): void{
    const compareToday = new Date();
    this.machineIrregularDataList.length = 0;
    const today = new Date();

    //取得データの始点(データ取得後に使用する)
    let fromTime = new Date(today.setHours(0, 0, 0, 0));
    //取得データの終点(データ取得後に使用する)
    let toTime = new Date(today.setHours(0, 0, 0, 0));

    let timeAxisCount = 0;

    //0時から6時間ずつ追加する(現在時刻を超えたタイミングでループ終了)
    while (compareToday > toTime) {
      toTime = new Date(toTime.setHours(toTime.getHours() + 6));
      timeAxisCount++;
    }

    //5はグラフの表示ブロック数、1ブロック6時間分
    timeAxisCount = 5 - timeAxisCount;

    //0時から6時間ずつ減算する(一日前のデータをどこまで取得するか)
    for (let i = 0; i < timeAxisCount; i++) {
      fromTime = new Date(fromTime.setHours(fromTime.getHours() - 6));
    }

    if(this.searchMode == true){

      this.machineIrregularSendData = {
        cityId: this.currentCityId,
        fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.dataFormGroup.value.todatetime)).add(1, 'd').format('YYYY-MM-DD')
      }
    }
    //日付が選択されていないときの送信データ
    else{

      this.machineIrregularSendData = {
        cityId: this.currentCityId,
        fromDate: moment(new Date(today)).subtract(30, 'h').format("YYYY-MM-DD"),
        toDate: moment(new Date(today)).add(1, 'd').format("YYYY-MM-DD"),
      };
    }

    /* const sendData =
    {
    cityId: this.currentCityId,
    fromDate: moment(new Date(today)).subtract(30,'h').format("YYYY-MM-DD"),
    toDate: moment(new Date(today)).add(1,'d').format("YYYY-MM-DD"),
    } */
    //const today = new Date();
    //const filterDay = moment(new Date(today)).subtract(30,'h').format("YYYY-MM-DD");

    this.todoService.getAlertsByCityIdAndDate(this.machineIrregularSendData).subscribe((response: any) => {
      this.allAlertData = [];
      this.machineIrregularDataList = [];
      //20231116
      //絞り込みなしの場合はデフォルトの表示日時のデータだけになるよう抽出する
      if(this.searchMode == false){

        for (let index = 0; index < response.alertResponses.length; index++) {
          var tmpVal: string;
          let date = new Date(response.alertResponses[index].dataTimeStamp * 1000);
          tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

          let tmpFrom = moment(new Date(fromTime)).format("YYYY-MM-DD HH:mm");
          let tmpTo = moment(new Date(toTime)).format("YYYY-MM-DD HH:mm");

          if (tmpVal >= tmpFrom) {

            if (tmpVal <= tmpTo) {
              this.allAlertData.push(response.alertResponses[index]);
            }
          }
        }
      }
      else{

        for (let index = 0; index < response.alertResponses.length; index++) {
          var tmpVal: string;
          let date = new Date(response.alertResponses[index].dataTimeStamp * 1000);
          tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

          let tmpFrom = this.machineIrregularSendData.fromDate;
          let tmpTo = moment.utc(new Date(this.machineIrregularSendData.toDate)).format("YYYY-MM-DD HH:mm");

          if (tmpVal >= tmpFrom) {

            if (tmpVal < tmpTo) {
              this.allAlertData.push(response.alertResponses[index]);
            }
          }
        }
      }

      for (let totalIndex = 0; totalIndex < this.allAlertData.length; totalIndex++) {

        let date = new Date(this.allAlertData[totalIndex].dataTimeStamp * 1000);
        let displayedDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm");
        let dataUnit = "";

        //吉原対応_項番123
        switch (this.allAlertData[totalIndex]['dataName']){

          case 'turbidity':
            dataUnit = "(FTU)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(1);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(1);
            }
            break;

          case 'waterLevel':
            dataUnit = "(m)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(3);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(3);
            }
            break;

          case 'waterQuantity':
            dataUnit = "(㎥/sec)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(3);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(3);
            }
            break;

          case 'freshwaterConductivity':
            dataUnit = "(μS/cm)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(1);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(1);
            }
            break;

          case 'seawaterConductivity':
            dataUnit = "(mS/cm)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(2);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(2);
            }
            break;

          case 'waterTemperature':
            dataUnit = "(℃)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(2);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(2);
            }
            break;

          case 'battery_voltage':
          case 'batteryVoltage':
            dataUnit = "(V)";

            if(this.allAlertData[totalIndex]['value'] != null){
              this.irregularValue = (this.allAlertData[totalIndex]['value']).toFixed(3);
              this.irregularThresholdValue = (this.allAlertData[totalIndex]['threshold']).toFixed(3);
            }
            break;

          default:
            break;
        }

        const alertInfo: MachineIrregularData = {
          Datetime: displayedDate,
          Sensor: this.allAlertData[totalIndex]['dataNameToDisplay'],
          Unit: dataUnit,
          ThresholdValue: this.irregularThresholdValue,
          Value: this.irregularValue,
          Detail: this.allAlertData[totalIndex]['status'],
          City: this.allAlertData[totalIndex]['cityName'],
          Area: this.allAlertData[totalIndex]['areaName'],
          Point: this.allAlertData[totalIndex]['sensorName']
        }

        //データのnullチェック(nullの場合は'-'にする)
        alertInfo.Value = isNull(alertInfo.Value);

        this.machineIrregularDataList.push(alertInfo);
        //20231116
      }

      /* if(this.machineIrregularDataList.length > 0)
      {
      this.machineIrregularDataList.length = 0;
      } */
      //this.machineIrregularDataList
      //  for(let index = 0; index < this.allThresholdValueResponse.length; index++)
      //   {
      //    const wlal = this.allThresholdValueResponse[index].filter((x:any)=>x.dataPointName == "S001");//水位
      //    const wqal = this.allThresholdValueResponse[index].filter((x:any)=>x.dataPointName == "S005");//流量
      //   }

      //地区が選ばれているとき
      if(this.isAreaSelected == true){
        let copyMachineIrregulars = _.cloneDeep(this.machineIrregularDataList);
        const selectedMachineIrregularData = copyMachineIrregulars.filter((x: any) => x.Area == this.selectedAreaName);
        this.machineIrregularDataLength = selectedMachineIrregularData.length;
        this.machineIrregularPagesize = [10, 50, 100];
        this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(selectedMachineIrregularData);
        this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
      }

      //地点が選ばれているとき
      if(this.isSensorSelected == true){
        let copyMachineIrregulars = _.cloneDeep(this.machineIrregularDataList);
        const selectedMachineIrregularData = copyMachineIrregulars.filter((x: any) => x.Point == this.selectedPointName);
        this.machineIrregularDataLength = selectedMachineIrregularData.length;
        this.machineIrregularPagesize = [10, 50, 100];
        this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(selectedMachineIrregularData);
        this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
      }

      //何も選ばれていないとき
      if(this.isAreaSelected == false && this.isSensorSelected == false){

        // 日時、地点でソートする
        this.machineIrregularDataList.sort(function (a, b) {
          if (a.Datetime !== b.Datetime) {
            return a.Datetime < b.Datetime ? -1 : 1;
          }
          return (a.Point < b.Point) ? -1 : 1;
        })

        this.machineIrregularDataLength = this.machineIrregularDataList.length;
        this.machineIrregularPagesize = [10, 50, 100];
        this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataList);
        this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
      }

      if(this.isCompleteProcess == false){
        this.isCompleteProcess = true;
      }
      else this.spinner.hide();

      this.spinner.hide();
    },
    (error: any) => {
      this.spinner.hide();
      this.searchMode  = false;
      // console.log(error);
    });
  }

  //閾値超過画面の表示
  showThresholdAlerts(): void{
    this.thresholdAlertArray.length = 0;
    let count = 0;
    let formedThresholdVal = 0;
    let formedVal: any;

    const today = new Date();
    const compareToday = new Date();
    let unit = "";

    //取得データの始点(データ取得後に使用する)
    let fromTime = new Date(today.setHours(0, 0, 0, 0));
    //取得データの終点(データ取得後に使用する)
    let toTime = new Date(today.setHours(0, 0, 0, 0));

    let timeAxisCount = 0;

    //0時から6時間ずつ追加する(現在時刻を超えたタイミングでループ終了)
    while (compareToday > toTime) {
      toTime = new Date(toTime.setHours(toTime.getHours() + 6));
      timeAxisCount++;
    }

    //5はグラフの表示ブロック数、1ブロック6時間分
    timeAxisCount = 5 - timeAxisCount;

    //0時から6時間ずつ減算する(一日前のデータをどこまで取得するか)
    for (let i = 0; i < timeAxisCount; i++) {
      fromTime = new Date(fromTime.setHours(fromTime.getHours() - 6));
    }

    //日付が選択されているときの送信データ
    if(this.searchMode == true){

      this.thresholdAlertSendData = {
        cityId: this.currentCityId,
        fromDate: moment(new Date(this.dataFormGroup.value.fromdatetime)).format('YYYY-MM-DD'),
        toDate: moment(new Date(this.dataFormGroup.value.todatetime)).format('YYYY-MM-DD')
      }
    }

    //日付が選択されていないときの送信データ
    else{

      //修正前
      this.thresholdAlertSendData = {
        cityId: this.currentCityId,
        fromDate: moment(new Date(today)).subtract(1, 'd').format("YYYY-MM-DD"),
        toDate: moment(new Date(today)).add(1, 'd').format("YYYY-MM-DD")
      };

      //修正後
      // this.thresholdAlertSendData = {
      //   cityId: this.currentCityId,
      //   fromDate: moment(new Date(today)).subtract(30, 'h').format("YYYY-MM-DD"),
      //   toDate: moment(new Date(today)).format("YYYY-MM-DD"),
      // };
    }

    //バックエンドにリクエストを送信する
    this.todoService.getThresholdAlertsByCityId(this.thresholdAlertSendData).subscribe((response: any) => {
      let tempArray: any[] = [];
      this.thresholdAlertArray = [];
      //20231120
      if(response != null){

        if(response.thresholdAlertList != null && response.thresholdAlertList.length != 0){

          if(this.searchMode == true){

            for (let i = 0; i < response.thresholdAlertList.length; i++) {
              var tmpVal: string;
              let date = new Date(response.thresholdAlertList[i].dataTimeStamp * 1000);
              tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

              let tmpFrom = this.thresholdAlertSendData.fromDate;
              let tmpTo = moment.utc(new Date(this.thresholdAlertSendData.toDate)).add(1, 'd').format("YYYY-MM-DD HH:mm");

              //let tmpFrom = moment(new Date(fromTime)).format("YYYY-MM-DD HH:mm");
              //let tmpTo = moment(new Date(toTime)).format("YYYY-MM-DD HH:mm");

              if (tmpVal >= tmpFrom) {

                if (tmpVal < tmpTo) {
                  tempArray.push(response.thresholdAlertList[i]);
                }
              }
            }
          }
          else{

            for (let i = 0; i < response.thresholdAlertList.length; i++) {
              var tmpVal: string;
              let date = new Date(response.thresholdAlertList[i].dataTimeStamp * 1000);
              tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

              let tmpFrom = moment(new Date(fromTime)).format("YYYY-MM-DD HH:mm");
              let tmpTo = moment(new Date(toTime)).format("YYYY-MM-DD HH:mm");

              if (tmpVal >= tmpFrom) {

                if (tmpVal <= tmpTo) {
                  tempArray.push(response.thresholdAlertList[i]);
                }
              }
            }
          }

          for(let index = 0; index < tempArray.length; index++){

            let item = tempArray[index];

            if(item['dataName'] == "water_quantity"){
              unit = "(㎥/sec)";
            }
            //20240520追加
            else if(item['dataName'] == "turbidity_predict") {
              unit = "(度)";
            }
            //20240520追加終了
            else{
              unit = "(m)";
            }

            let date = new Date((item['dataTimeStamp']) * 1000);
            let convertedDate = moment(date).format("YYYY-MM-DD HH:mm");

            formedThresholdVal = (item['threshold']).toFixed(3);

            if(item['value'] != null){
              formedVal = (item['value']).toFixed(3);
            }
            else{
              formedVal = "-";
            }

            const alertItem: thresholdAlert = {
              Datetime: convertedDate,
              City: item['cityName'],
              Area: item['areaName'],
              Point: item['pointName'],
              Sensor: item['dataName'],
              Unit: unit,
              AlertLevel: item['level'],
              ThresholdValue: formedThresholdVal,
              Value: formedVal
            }
            this.thresholdAlertArray.push(alertItem);
          }

          if(this.thresholdAlertArray.length != 0){

            //20240520追加
            //濁度オプションがOFFの場合に濁度予測を除外
            if(!this.isEnableTurbidity) {
              this.thresholdAlertArray = this.thresholdAlertArray.filter((x: any) => x.Sensor != "turbidity_predict")
            }
            //20240520追加終了

            //地区が選ばれているとき
            if(this.isAreaSelected == true){
              let copyThresholds = _.cloneDeep(this.thresholdAlertArray);
              const selectedThresholdData = copyThresholds.filter((x: any) => x.Area == this.selectedAreaName);
              this.thresholdDataLength = selectedThresholdData.length;
              this.thresholdPagesize = [10, 50, 100];
              this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(selectedThresholdData);
              this.extraThresholdDataSource.paginator = this.thresholdPaginator;
            }

            //地点が選ばれているとき
            if(this.isSensorSelected == true){
              let copyThresholds = _.cloneDeep(this.thresholdAlertArray);
              const selectedThresholdData = copyThresholds.filter((x: any) => x.Point == this.selectedPointName);
              this.thresholdDataLength = selectedThresholdData.length;
              this.thresholdPagesize = [10, 50, 100];
              this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(selectedThresholdData);
              this.extraThresholdDataSource.paginator = this.thresholdPaginator;
            }

            //何も選ばれていないとき
            if(this.isAreaSelected == false && this.isSensorSelected == false){

              // 日時、地点でソートする
              this.thresholdAlertArray.sort(function (a, b) {
                if (a.Datetime !== b.Datetime) {
                  return a.Datetime < b.Datetime ? -1 : 1;
                }
                return (a.Point < b.Point) ? -1 : 1;
              })

              this.thresholdDataLength = this.thresholdAlertArray.length;
              this.thresholdPagesize = [10, 50, 100];
              this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.thresholdAlertArray);
              this.extraThresholdDataSource.paginator = this.thresholdPaginator;
            }
          }
        }
        else
        {
          this.spinner.hide();
        }
      }
      else this.spinner.hide();
      //20231120
    }, (error: any) => {
      this.spinner.hide();
      this.searchMode  = false;
    })
  }

  //取得したデータを閾値超過表示用に集約する(今後使用しない)
  // makeExtraThresholdData(): void{
  //   this.extraThresholdDataList.length = 0;
  //   //this.extraThresholdDataSource.length = 0;

  //   if(this.allThresholdValueResponse !== undefined && this.responseDataSource !== undefined){

  //     for(let index = 0; index < this.allThresholdValueResponse.length; index++){
  //       const wlal = this.allThresholdValueResponse[index].filter((x: any) => x.dataPointName == "S001");//水位
  //       const wqal = this.allThresholdValueResponse[index].filter((x: any) => x.dataPointName == "S005");//流量
  //       const pickedMainSensorId = this.allThresholdValueResponse[index][0]['mainSensorId'];

  //       //メインセンサーIDが等しいものを抜き出す
  //       for(let a = 0; a < this.responseDataSource.length; a++){
  //         const searchedData = this.responseDataSource[a].waterFlowResponse.filter((x: any) => x.mainSensorId == pickedMainSensorId);

  //         if(searchedData.length !== 0){
  //           this.pointData = searchedData;
  //         }
  //       }

  //       if(wlal.length > 0 || wqal.length > 0){
  //         this.waterLevelAlertLevel1 = wlal[0].alertLevel1;
  //         this.waterLevelAlertLevel2 = wlal[0].alertLevel2;
  //         this.waterLevelAlertLevel3 = wlal[0].alertLevel3;
  //         //this.waterLevelCorrectionValue = wlal[0].correctionValue;
  //         this.waterQuantityAlertLevel1 = wqal[0].alertLevel1;
  //         this.waterQuantityAlertLevel2 = wqal[0].alertLevel2;
  //         this.waterQuantityAlertLevel3 = wqal[0].alertLevel3;
  //         //this.waterQuantityCorrectionValue = wqal[0].correctionValue;

  //         //閾値超過をチェック
  //         //const pointData = this.responseDataSource[index].waterFlowResponse;
  //         for(let i = 0; i < this.pointData.length; i++){

  //           //小数点の調整と数値型への変更(水位)
  //           if(this.pointData[i]['waterLevel'] !== null){
  //             this.pointData[i]['waterLevel'] = parseFloat(this.pointData[i]['waterLevel']).toFixed(3);
  //           }

  //           if(this.pointData[i]['rawWaterLevel'] !== null){
  //             this.pointData[i]['rawWaterLevel'] = parseFloat(this.pointData[i]['rawWaterLevel']).toFixed(3);
  //           }

  //           //小数点の調整と数値型への変更(流量)
  //           if(this.pointData[i]['waterQuantity'] !== null){
  //             this.pointData[i]['waterQuantity'] = parseFloat(this.pointData[i]['waterQuantity']).toFixed(3);
  //           }

  //           if(this.pointData[i]['rawWaterQuantity'] !== null){
  //             this.pointData[i]['rawWaterQuantity'] = parseFloat(this.pointData[i]['rawWaterQuantity']).toFixed(3);
  //           }

  //           //水位警戒レベル3以上を抽出
  //           if(this.pointData[i]['waterLevel'] >= this.waterLevelAlertLevel3){

  //             const filteredData: ExtraThresholdData = {
  //               Datetime: this.pointData[i]['dateTime'],
  //               City: this.pointData[i]['cityName'],
  //               Area: this.pointData[i]['areaName'],
  //               Point: this.pointData[i]['sensorName'],
  //               Sensor: "waterLevel",
  //               Unit: "(m)",
  //               AlertLevel: 3,
  //               ThresholdValue: this.waterLevelAlertLevel3,
  //               Value: parseFloat(this.pointData[i]['waterLevel']),
  //             }
  //             this.extraThresholdDataList.push(filteredData);
  //           }

  //           //水位警戒レベル2以上3未満を抽出
  //           if(this.pointData[i]['waterLevel'] >= this.waterLevelAlertLevel2
  //           && this.pointData[i]['waterLevel'] < this.waterLevelAlertLevel3){

  //             const filteredData: ExtraThresholdData = {
  //               Datetime: this.pointData[i]['dateTime'],
  //               City: this.pointData[i]['cityName'],
  //               Area: this.pointData[i]['areaName'],
  //               Point: this.pointData[i]['sensorName'],
  //               Sensor: "waterLevel",
  //               Unit: "(m)",
  //               AlertLevel: 2,
  //               ThresholdValue: this.waterLevelAlertLevel2,
  //               Value: parseFloat(this.pointData[i]['waterLevel']),
  //             }
  //             this.extraThresholdDataList.push(filteredData);
  //           }

  //           //水位警戒レベル1以上2未満を抽出
  //           if(this.pointData[i]['waterLevel'] >= this.waterLevelAlertLevel1
  //           && this.pointData[i]['waterLevel'] < this.waterLevelAlertLevel2){

  //             const filteredData: ExtraThresholdData = {
  //               Datetime: this.pointData[i]['dateTime'],
  //               City: this.pointData[i]['cityName'],
  //               Area: this.pointData[i]['areaName'],
  //               Point: this.pointData[i]['sensorName'],
  //               Sensor: "waterLevel",
  //               Unit: "(m)",
  //               AlertLevel: 1,
  //               ThresholdValue: this.waterLevelAlertLevel1,
  //               Value: parseFloat(this.pointData[i]['waterLevel']),
  //             }
  //             this.extraThresholdDataList.push(filteredData);
  //           }

  //           //流量警戒レベル3以上を抽出
  //           if(this.pointData[i]['waterQuantity'] >= this.waterQuantityAlertLevel3){

  //             const filteredData: ExtraThresholdData = {
  //               Datetime: this.pointData[i]['dateTime'],
  //               City: this.pointData[i]['cityName'],
  //               Area: this.pointData[i]['areaName'],
  //               Point: this.pointData[i]['sensorName'],
  //               Sensor: "waterQuantity",
  //               Unit: "(㎥/sec)",
  //               AlertLevel: 3,
  //               ThresholdValue: this.waterQuantityAlertLevel3,
  //               Value: parseFloat(this.pointData[i]['waterQuantity']),
  //             }
  //             this.extraThresholdDataList.push(filteredData);
  //           }

  //           //流量警戒レベル2以上3未満を抽出
  //           if(this.pointData[i]['waterQuantity'] >= this.waterQuantityAlertLevel2
  //           && this.pointData[i]['waterQuantity'] < this.waterQuantityAlertLevel3){

  //             const filteredData: ExtraThresholdData = {
  //               Datetime: this.pointData[i]['dateTime'],
  //               City: this.pointData[i]['cityName'],
  //               Area: this.pointData[i]['areaName'],
  //               Point: this.pointData[i]['sensorName'],
  //               Sensor: "waterQuantity",
  //               Unit: "(㎥/sec)",
  //               AlertLevel: 2,
  //               ThresholdValue: this.waterQuantityAlertLevel2,
  //               Value: parseFloat(this.pointData[i]['waterQuantity']),
  //             }
  //             this.extraThresholdDataList.push(filteredData);
  //           }

  //           //流量警戒レベル1以上2未満を抽出
  //           if(this.pointData[i]['waterQuantity'] >= this.waterQuantityAlertLevel1
  //           && this.pointData[i]['waterQuantity'] < this.waterQuantityAlertLevel2){

  //             const filteredData: ExtraThresholdData = {
  //               Datetime: this.pointData[i]['dateTime'],
  //               City: this.pointData[i]['cityName'],
  //               Area: this.pointData[i]['areaName'],
  //               Point: this.pointData[i]['sensorName'],
  //               Sensor: "waterQuantity",
  //               Unit: "(㎥/sec)",
  //               AlertLevel: 1,
  //               ThresholdValue: this.waterQuantityAlertLevel1,
  //               Value: parseFloat(this.pointData[i]['waterQuantity']),
  //             }
  //             this.extraThresholdDataList.push(filteredData);
  //           }
  //         }

  //         //地区が選ばれているとき
  //         if(this.isAreaSelected == true){
  //           let copyThresholds = _.cloneDeep(this.extraThresholdDataList);
  //           const selectedThresholdData = copyThresholds.filter((x: any) => x.Area == this.selectedAreaName);
  //           this.thresholdDataLength = selectedThresholdData.length;
  //           this.thresholdPagesize = [10, 50, 100];
  //           this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(selectedThresholdData);
  //           this.extraThresholdDataSource.paginator = this.thresholdPaginator;
  //         }

  //         //地点が選ばれているとき
  //         if(this.isSensorSelected == true){
  //           let copyThresholds = _.cloneDeep(this.extraThresholdDataList);
  //           const selectedThresholdData = copyThresholds.filter((x: any) => x.Point == this.selectedPointName);
  //           this.thresholdDataLength = selectedThresholdData.length;
  //           this.thresholdPagesize = [10, 50, 100];
  //           this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(selectedThresholdData);
  //           this.extraThresholdDataSource.paginator = this.thresholdPaginator;
  //         }

  //         //何も選ばれていないとき
  //         if(this.isAreaSelected == false && this.isSensorSelected == false){

  //           //日時でソートする
  //           this.extraThresholdDataList.sort(function (a, b) {
  //             return (a.Datetime < b.Datetime) ? -1 : 1;
  //           })

  //           this.thresholdDataLength = this.extraThresholdDataList.length;
  //           this.thresholdPagesize = [10, 50, 100];
  //           this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.extraThresholdDataList);
  //           this.extraThresholdDataSource.paginator = this.thresholdPaginator;
  //         }
  //       }
  //     }

  //     if(this.isCompleteProcess == false){
  //       this.isCompleteProcess = true;
  //     }
  //     else this.spinner.hide();
  //   }
  // }

  //ソート機能(閾値超過)
  sortingThreshold(): void{
    this.extraThresholdDataSource.sort = this.thresholdSortItem;
  }

  //ソート機能(機器異常)
  sortingMachineIrregular(): void{
    this.machineIrregularDataSource.sort = this.machineIrregularSortItem;
  }

  //地区・地点の絞り込みのために地区名と地点名を取得する
  getSensorNamesAndAreaNames(): void{

    this.todoService.getSensorNamesAndAreaNamesByCityId(this.currentCityId).subscribe((response: any) => {
      this.areasArray.length = 0;
      this.sensorsArray.length = 0;
      let data = response;

      for(let i = 0; i < data.length; i++){

        const areas = {
          areaName: data[i].areaName
        }
        const isIncludeSameArea = this.areasArray.some((x: any) => x.areaName == areas.areaName);

        if(isIncludeSameArea == false){
          this.areasArray.push(areas);
        }

        const areaSensors = {
          areaName: data[i].areaName,
          sensorName: data[i].sensorName
        }

        this.sensorsArray.push(areaSensors);

        //const isIncludeSameSensor = this.sensorsArray.some((x: any) => x.sensorName == areaSensors.sensorName);
      }

      this.copyAreasArray = _.cloneDeep(this.areasArray);
      this.copySensorsArray = _.cloneDeep(this.sensorsArray);

      //以下、そのままのソートでは対応できなかったため回りくどくなった
      this.areasArray.length = 0;
      this.sensorsArray.length = 0;

      this.copyAreasArray.sort(function (a :any, b: any) {
        return (a.areaName < b.areaName) ? -1 : 1;
      });
      this.copySensorsArray.sort(function (a: any, b: any) {
        return (a.sensorName < b.sensorName) ? -1 : 1;
      });

      for(let i = 0; i < this.copyAreasArray.length; i++){
        this.areasArray.push(this.copyAreasArray[i]);
      }

      for(let i = 0; i < this.copySensorsArray.length; i++){
        this.sensorsArray.push(this.copySensorsArray[i]);
      }

      this.spinner.hide();
    }, (error: any) => {
      this.spinner.hide();
      this.searchMode  = false;
    });
  }

  //地区または地点を選んだ時のセレクトボックスの動作
  handleSelection(item: any, selected: string): void{

    //地区を選択した場合
    if(selected == "area"){
      this.isDisablePointSelect = false;
      this.isAreaSelected = true;
      this.selectedAreaName = item.areaName;
      this.sensorsArray = this.copySensorsArray.filter((x: any) => x.areaName == item.areaName);

      //閾値超過
      let copyThresholds = _.cloneDeep(this.thresholdAlertArray);
      const selectedThresholdData = copyThresholds.filter((x: any) => x.Area == item.areaName);
      this.thresholdDataLength = selectedThresholdData.length;
      this.thresholdPagesize = [10, 50, 100];
      this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(selectedThresholdData);
      this.extraThresholdDataSource.paginator = this.thresholdPaginator;

      //機器異常
      let copyMachineIrregulars = _.cloneDeep(this.machineIrregularDataList);
      const selectedMachineIrregularData = copyMachineIrregulars.filter((x: any) => x.Area == item.areaName);
      this.machineIrregularDataLength = selectedMachineIrregularData.length;
      this.machineIrregularPagesize = [10, 50, 100];
      this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(selectedMachineIrregularData);
      this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
    }

    //地点を選択した場合
    else if(selected == "point"){
      this.isSensorSelected = true;
      this.selectedPointName = item.sensorName;

      //閾値超過
      let copyThresholds = _.cloneDeep(this.thresholdAlertArray);
      const selectedThresholdData = copyThresholds.filter((x: any) => x.Point == item.sensorName);
      this.thresholdDataLength = selectedThresholdData.length;
      this.thresholdPagesize = [10, 50, 100];
      this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(selectedThresholdData);
      this.extraThresholdDataSource.paginator = this.thresholdPaginator;

      //機器異常
      let copyMachineIrregulars = _.cloneDeep(this.machineIrregularDataList);
      const selectedMachineIrregularData = copyMachineIrregulars.filter((x: any) => x.Point == item.sensorName);
      this.machineIrregularDataLength = selectedMachineIrregularData.length;
      this.machineIrregularPagesize = [10, 50, 100];
      this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(selectedMachineIrregularData);
      this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
    }

    //未選択を押した場合
    else if(selected == "not-selected"){

      if(item == "area"){
        this.isAreaSelected = false;
        this.isSensorSelected = false;
        this.isDisablePointSelect = true;
        this.selectedAreaName = "";
        this.selectedPointName = "";
        this.defaultSensorVal = "Select";
        //todo mat-selectの操作方法
        //let pointIndex = (document.getElementById('pointSelect') as HTMLSelectElement);

        //地区の絞り込み解除→元のデータリストを再利用
        //閾値超過
        this.thresholdDataLength = this.thresholdAlertArray.length;
        this.thresholdPagesize = [10, 50, 100];
        this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(this.thresholdAlertArray);
        this.extraThresholdDataSource.paginator = this.thresholdPaginator;

        //機器異常
        this.machineIrregularDataLength = this.machineIrregularDataList.length;
        this.machineIrregularPagesize = [10, 50, 100];
        this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(this.machineIrregularDataList);
        this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;

        this.sensorsArray = _.cloneDeep(this.copySensorsArray);
      }
      else{
        this.isSensorSelected = false;
        this.selectedPointName = "";

        //地点の絞り込み解除→地区で絞り込み実施
        //閾値超過
        let copyThresholds = _.cloneDeep(this.thresholdAlertArray);
        const selectedThresholdData = copyThresholds.filter((x: any) => x.Area == this.selectedAreaName);
        this.thresholdDataLength = selectedThresholdData.length;
        this.thresholdPagesize = [10, 50, 100];
        this.extraThresholdDataSource = new MatTableDataSource<ExtraThresholdData>(selectedThresholdData);
        this.extraThresholdDataSource.paginator = this.thresholdPaginator;

        //機器異常
        let copyMachineIrregulars = _.cloneDeep(this.machineIrregularDataList);
        const selectedMachineIrregularData = copyMachineIrregulars.filter((x: any) => x.Area == this.selectedAreaName);
        this.machineIrregularDataLength = selectedMachineIrregularData.length;
        this.machineIrregularPagesize = [10, 50, 100];
        this.machineIrregularDataSource = new MatTableDataSource<MachineIrregularData>(selectedMachineIrregularData);
        this.machineIrregularDataSource.paginator = this.machineIrregularPaginator;
      }
    }
  }

  //CSV出力用ダイアログを開く
  selectOutputDate(): void{
    //  const details = document.querySelector<HTMLDetailsElement>('.details-dialog');
    //  const summary = document.querySelector<HTMLElement>('.exportbtn');
    //  //const tf = details?.open;
    //  summary?.addEventListener("click", (event) => {
    //    event.preventDefault();
    //    if(details?.open){}
    //   });
    //  const isSummaryClosed = (document.getElementById(`csvSummary`) as HTMLDetailsElement).open;
    //  (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = true;

    document.querySelector<HTMLElement>('.exportbtn')?.addEventListener("click", function(event){
      (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = true;
      event.preventDefault();
    }, false);
  }

  //CSV出力用ダイアログを閉じる
  closeSelectOutputDate(): void {
    const isSummaryClosed = (document.getElementById(`csvSummary`) as HTMLDetailsElement).closest;
    (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = false;
  }

  //CSV出力を実際に行う
  OutputCSVData(): void{
    (document.getElementById(`csvSummary`) as HTMLDetailsElement).open = false;
    const fd = (document.getElementById(`csvdt1`) as HTMLInputElement).value;
    const td = (document.getElementById(`csvdt2`) as HTMLInputElement).value;
    const today = new Date();
    let fileName = "";

    //現在使用中の言語を取得(今後の対応のため)
    let currrentLang = sessionStorage.getItem('lang');

    if(currrentLang == null || currrentLang == "" || currrentLang == undefined){
      currrentLang = this.cookieService.get('language');
    }

    if(this.currentView == "extraThreshold"){

      if(currrentLang == "jp"){
        fileName = "閾値超過"
      }
      else{
        fileName = "ExtraThreshold";
      }
      //fileName = "ExtraThreshold"
    }
    else{
       if(currrentLang == "jp"){
          fileName = "機器異常";
        }
       else{
          fileName = "MachineIrregular";
        }
        //fileName = "MachineIrregular";
    }

    this.options = {
      filename: `${fileName} - ${today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + ('0' + today.getDate()).slice(-2)
      + '-' + ('0' + today.getHours()).slice(-2) + ('0' + today.getMinutes()).slice(-2) + ('0' + today.getSeconds()).slice(-2)}`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      //title: this.cookieService.get('language') === 'en' ? 'Converted value' + allSensors : '測定値（A/D変換値）' + allSensors,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
    };

    if(this.currentView == "extraThreshold")
    {
      // ↓Mod 2023/12/26 課題No.90
      // this.makeThresholdCsv(fd, td);
      this.exportThresholdAlert(fd, td);
      // ↑Mod 2023/12/26 課題No.90
    }
    else
    {
      // ↓Mod 2023/12/25 課題No.90
      // this.makeIrregularCsv(fd, td);
      this.exportAlert(fd, td);
      // ↑Mod 2023/12/25 課題No.90
    }

    return;

    // //日付が選択されていた場合
    // if(fd !== "" && td !== ""){

    //   const sendData = {
    //     cityId: this.currentCityId,
    //     fromDate: moment(new Date(fd)).format('YYYY-MM-DD'),
    //     toDate: moment(new Date(td)).add(1, 'd').format('YYYY-MM-DD')
    //   };

    //   const isDateSelected = true;

    //   this.todoService.getAllByCityIdAndMultipleDates(sendData).subscribe((response: any) => {
    //     this.csvResponseData = _.cloneDeep(response);

    //     if(this.currentView == "extraThreshold"){
    //       this.csvThresholdOutput();
    //     }
    //     else{
    //       this.csvMachineIrregularOutout(isDateSelected);
    //     }
    //   })
    // }

    // //日付が選択されていない場合
    // else{

    //   const sendData = {
    //     cityId: this.currentCityId,
    //     fromDate: moment(new Date(today)).format('YYYY-MM-DD'),
    //     toDate: moment(new Date(today)).add(1, 'd').format('YYYY-MM-DD')
    //   };

    //   const isDateSelected = false;

    //   this.todoService.getAllByCityIdAndMultipleDates(sendData).subscribe((response: any) => {
    //     this.csvResponseData = _.cloneDeep(response);

    //     if(this.currentView == "extraThreshold"){
    //       this.csvThresholdOutput();
    //     }
    //     else{
    //       this.csvMachineIrregularOutout(isDateSelected);
    //     }
    //   })
    // }
  }

  //閾値超過をcsv出力する_20231120
  exportThresholdAlert(fromDate: string, toDate: string): void {
    let csvOutputDataList: any[] = [];

    //現在選択中の言語を取得(csvファイルをどの言語で出力するか決めるため)
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    //日付が入力されているかのチェック
    if(fromDate !== "" && fromDate !== undefined && fromDate !== null){
      if(toDate !== "" && toDate !== undefined && fromDate !== null){
        this.thresholdAlertSendData = {
          cityId: this.currentCityId,
          fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
          toDate: moment(new Date(toDate)).add(1, 'd').format('YYYY-MM-DD')
        };
      }
      else
      {
        alert("日付(To)が入力されていません。");
        return;
      }
    }
    else
    {
      alert("日付(From)が入力されていません。");
      return;
    }

    // リクエストデータ取得
    this.todoService.getThresholdAlertsByCityId(this.thresholdAlertSendData).subscribe((response: any) => {
      // console.log(this.thresholdAlertSendData, response);
      if (response != null && response.thresholdAlertList) {
        if (response.length <= 0) {
          this.spinner.hide();
          return;
        }

        for (let item of response.thresholdAlertList) {
          let unit = "";
          let sensor = "";
          let formedThresholdVal = 0;
          let formedVal = 0;

          switch (item["dataName"]) {
            case "water_quantity":
            case "waterQuantity":
              unit = "(㎥/sec)";

              if(lang == "jp")
              {
                sensor = "流量";
              }
              else if(lang == "en"){
                sensor = "Waterquantity";
              }
              break;

            //20240520追加
            case "turbidity_predict":
              unit = "(度)";
              if(lang == "jp")
                {
                  sensor = "濁度予測";
                }
                else if(lang == "en"){
                  sensor = "Turbiditypredict";
                  unit = "(FTU)";
                }
              break;
            //20240520追加終了

            default:
              unit = "(m)";
              if(lang == "jp")
              {
                sensor = "水位";
              }
              else if (lang == "en")
              {
                sensor = "Waterlevel";
              }
              break;
          }

          let date = new Date((item['dataTimeStamp']) * 1000);
          let convertedDate = moment(date).format("YYYY-MM-DD HH:mm");

          let tmpFrom = moment(new Date(fromDate)).format('YYYY-MM-DD HH:mm');
          let tmpTo = moment(new Date(toDate)).format("YYYY-MM-DD 23:59");

          if (convertedDate < tmpFrom) { continue; }
          if (convertedDate >= tmpTo) { continue; }
          // if (convertedDate >= tmpFrom) {
          //   if (convertedDate < tmpTo) {
          //     tempArray.push(response.alertResponses[index]);
          //   }
          // }

          formedThresholdVal = (item['threshold']).toFixed(3);

          if(item['value'] != null){
            formedVal = (item['value']).toFixed(3);
          }

          const alertItem: thresholdAlert = {
            Datetime: convertedDate,
            City: isNull(item['cityName']),
            Area: isNull(item['areaName']),
            Point: isNull(item['pointName']),
            Sensor: isNull(sensor),
            Unit: isNull(unit),
            AlertLevel: isNull(item['level']),
            ThresholdValue: isNull(formedThresholdVal),
            Value: isNull(formedVal),
          }
          csvOutputDataList.push(alertItem);
        }

        //20240520追加
        //濁度オプションがOFFの場合に濁度予測を除外
        if(!this.isEnableTurbidity) {
          csvOutputDataList = csvOutputDataList.filter((x: any) => x.Sensor != "濁度予測");
          csvOutputDataList = csvOutputDataList.filter((x: any) => x.Sensor != "Turbiditypredict");
        }
        //20240520追加終了

        //日時でソートする
        csvOutputDataList.sort(function (a, b) {
          // if (a.Point !== b.Point) {
          //   return a.Point < b.Point ? -1 : 1;
          // }
          // return (a.Datetime < b.Datetime) ? -1 : 1;
          if (a.Datetime !== b.Datetime) {
            return a.Datetime < b.Datetime ? -1 : 1;
          }
          return (a.Point < b.Point) ? -1 : 1;
        });

        //ソート後ヘッダーを入れる
        if(lang == "jp")
        {
          const headerItem: thresholdAlert = {
            Datetime: "日時",
            City: "都市",
            Area: "地区",
            Point: "地点",
            Sensor: "センサー",
            Unit: "単位",
            AlertLevel: "警戒レベル",
            ThresholdValue: "閾値",
            Value: "測定値",
          }
          csvOutputDataList.unshift(headerItem);
        }
        else if (lang == "en")
        {
          const headerItem: thresholdAlert = {
            Datetime: "Datetime",
            City: "City",
            Area: "Area",
            Point: "Point",
            Sensor: "Sensor",
            Unit: "Unit",
            AlertLevel: "Alertlevel",
            ThresholdValue: "Thresholdvalue",
            Value: "Value",
          }
          csvOutputDataList.unshift(headerItem);
        }

        const csvExporter = new ExportToCsv(this.options);
        csvExporter.generateCsv(csvOutputDataList);
      }
    });
  }

  // //閾値超過をcsv出力する(新)
  // makeThresholdCsv(fromDate: string, toDate: string): void{
  //   let csvOutputDataList: any[] = [];
  //   let count = 0;
  //   let unit = "";
  //   let formedThresholdVal = 0;
  //   let formedVal = 0;
  //   let lang = sessionStorage.getItem("lang");
  //   //let csvOutputDataList: any[] = [];
  //   let sensor = "";
  //   let today = new Date();

  //   if(lang == null || lang == "" || lang == undefined){
  //     lang = this.cookieService.get('language');
  //   }

  //   if(fromDate !== "" && fromDate !== undefined && fromDate !== null){

  //     if(toDate !== "" && toDate !== undefined && fromDate !== null){

  //       this.thresholdAlertSendData = {
  //         cityId: this.currentCityId,
  //         fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(toDate)).format('YYYY-MM-DD')
  //       }
  //     }
  //     else{

  //       this.thresholdAlertSendData = {
  //         cityId: this.currentCityId,
  //         fromDate: moment(new Date(today)).format("YYYY-MM-DD"),
  //         toDate: moment(new Date(today)).format("YYYY-MM-DD")
  //       };
  //     }
  //   }
  //   else{

  //     this.thresholdAlertSendData = {
  //       cityId: this.currentCityId,
  //       fromDate: moment(new Date(today)).subtract(30, 'h').format("YYYY-MM-DD"),
  //       toDate: moment(new Date(today)).format("YYYY-MM-DD")
  //     };
  //   }

  //   this.todoService.getThresholdAlertsByCityId(this.thresholdAlertSendData).subscribe((response: any) => {

  //     if(response.length != 0){

  //       for(let index = 0; index < response.length; index++){
  //         let item = response[index].thresholdAlertList;

  //         if(item.length != 0){

  //           for(let subIndex = 0; subIndex < item.length; subIndex++){

  //             if(item[subIndex]['dataName'] == "water_quantity"){
  //               unit = "(㎥/sec)";

  //               if(lang == "jp"){
  //                 sensor = "流量";
  //               }
  //               else if(lang == "en"){
  //                 sensor = "Waterquantity";
  //               }
  //             }
  //             else{
  //               unit = "(m)";

  //               if(lang == "jp"){
  //                 sensor = "水位";
  //               }
  //               else if(lang == "en"){
  //                 sensor = "Waterlevel";
  //               }
  //             }

  //             let date = new Date((item[subIndex]['dataTimeStamp']) * 1000);
  //             let convertedDate = moment(date).format("YYYY-MM-DD HH:mm");

  //             formedThresholdVal = (item[subIndex]['threshold']).toFixed(3);

  //             if(item[subIndex]['value'] != null){
  //               formedVal = (item[subIndex]['value']).toFixed(3);
  //             }

  //             const alertItem: thresholdAlert = {
  //               Datetime: convertedDate,
  //               City: item[subIndex]['cityName'],
  //               Area: item[subIndex]['areaName'],
  //               Point: item[subIndex]['pointName'],
  //               Sensor: sensor,
  //               Unit: unit,
  //               AlertLevel: item[subIndex]['level'],
  //               ThresholdValue: formedThresholdVal,
  //               Value: formedVal,
  //             }
  //             csvOutputDataList.push(alertItem);
  //           }
  //         }
  //         else{
  //           count++;

  //           if(count == response.length){
  //             this.spinner.hide();
  //           }
  //         }
  //       }

  //       //日時でソートする
  //       this.thresholdAlertArray.sort(function (a, b) {
  //         return (a.Datetime < b.Datetime) ? -1 : 1;
  //       });

  //       //ソート後ヘッダーを入れる
  //       if(lang == "jp"){

  //         const headerItem: thresholdAlert = {
  //           Datetime: "日時",
  //           City: "都市",
  //           Area: "地区",
  //           Point: "地点",
  //           Sensor: "センサー",
  //           Unit: "単位",
  //           AlertLevel: "警戒レベル",
  //           ThresholdValue: "閾値",
  //           Value: "測定値",
  //         }
  //         csvOutputDataList.unshift(headerItem);
  //       }
  //       else if(lang == "en"){

  //         const headerItem: thresholdAlert = {
  //           Datetime: "Datetime",
  //           City: "City",
  //           Area: "Area",
  //           Point: "Point",
  //           Sensor: "Sensor",
  //           Unit: "Unit",
  //           AlertLevel: "Alertlevel",
  //           ThresholdValue: "Thresholdvalue",
  //           Value: "Value",
  //         }
  //         csvOutputDataList.unshift(headerItem);
  //       }

  //       const csvExporter = new ExportToCsv(this.options);
  //       csvExporter.generateCsv(csvOutputDataList);
  //       //this.snotifyService.success(this.translate.instant('Data exported successfully'), '');

  //       // if(this.isCompleteProcess == false){
  //       //   this.isCompleteProcess = true;
  //       // }
  //       // else this.spinner.hide();
  //     }
  //     else{
  //       this.spinner.hide();
  //       //データがないときは「データがありません」等のメッセージを表示する
  //     }
  //   })
  // }

  //機器異常をcsv出力する_20231120
  exportAlert(fromDate: string, toDate: string): void{
    const today = new Date();
    let csvOutputDataList: any[] = [];
    let requestData = {};
    let tempArray: any[] = [];
    let isDateFilter: boolean = false; //日付選択のフラグ

    //現在選択中の言語を取得(csvファイルをどの言語で出力するか決めるため)
    let lang = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    //日付が指定されているか判定する(FromTo両方指定されていなければデフォルトの日付)
    if(fromDate !== "" && fromDate !== undefined && fromDate !== null){
      if(toDate !== "" && toDate !== undefined && toDate !== null){
        isDateFilter = true;

        const sendData = {
          cityId: this.currentCityId,
          fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
          toDate: moment(new Date(toDate)).add(1, 'd').format('YYYY-MM-DD')
        }
        this.csvAlertRequest = sendData;
      }
      else
      {
        isDateFilter = false;

        const sendData = {
          cityId: this.currentCityId,
          fromDate: moment(new Date(today)).format('YYYY-MM-DD'),
          toDate: moment(new Date(today)).add(2, 'day').format('YYYY-MM-DD')
        };
        this.csvAlertRequest = sendData;
      }
    }
    else
    {
      isDateFilter = false;

      const sendData = {
        cityId: this.currentCityId,
        fromDate: moment(new Date(today)).format('YYYY-MM-DD'),
        toDate: moment(new Date(today)).add(1, 'd').format('YYYY-MM-DD')
      };
      this.csvAlertRequest = sendData;
    }

    this.todoService.getAlertsByCityIdAndDate(this.csvAlertRequest).subscribe((response: any) => {
    // this.todoService.getAlertsByFilterDate(this.csvAlertRequest).subscribe((response: any) => {
      //存在チェック
      if(response == null){
        return;
      }

      //存在チェック
      if(response.alertResponses.length == 0){
        return;
      }

      //表示日時のデータだけになるよう抽出する
      for (let index = 0; index < response.alertResponses.length; index++) {
        var tmpVal: string;
        let date = new Date(response.alertResponses[index].dataTimeStamp * 1000);
        tmpVal = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

        let tmpFrom = moment(new Date(fromDate)).format('YYYY-MM-DD HH:mm');
        let tmpTo = moment(new Date(toDate)).format("YYYY-MM-DD 23:59");
        // let tmpTo = moment.utc(new Date(moment(new Date(toDate)).format('YYYY-MM-DD'))).format("YYYY-MM-DD HH:mm");

        if (tmpVal >= tmpFrom) {

          if (tmpVal < tmpTo) {
            tempArray.push(response.alertResponses[index]);
          }
        }
      }

      //存在チェック
      if(tempArray.length == 0){
        return;
      }

      for (let index = 0; index < tempArray.length; index++)
      {
        let date = new Date(tempArray[index].dataTimeStamp * 1000);
        let displayedDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm");
        let irregularThresholdVal: any;
        let irregularVal: any;
        let dataUnit: string = "";
        let sensorName: string = "-";
        let detailMessage: string = "";
        let item = tempArray[index];

        //アラートの内容チェック
        switch (item['status']){
          //上限値超過
          case "Upper value  exceeded":
            if(lang == "jp"){
              detailMessage = "上限値を超過しています"
            }
            else if(lang == "en"){
              detailMessage = "Upper limit exceeded"
            }
            break;
          //下限値超過
          case "Lower value  exceeded":
            if(lang == "jp"){
              detailMessage = "下限値を超過しています"
            }
            else if(lang == "en"){
              detailMessage = "Lower limit exceeded"
            }
            break;
          //電池切れ
          case "Battery dead":
            if(lang == "jp"){
              detailMessage = "電池切れ"
            }
            else if(lang == "en"){
              detailMessage = "Battery dead"
            }
            break;
          //その他
          default:
            break;
        }

        // データ種別チェック
        switch (item['dataName']){
          case 'turbidity':
            dataUnit = "(FTU)";
            if(lang == "jp"){
              sensorName = "濁度";
            }
            else if(lang == "en"){
              sensorName = "Turbidity";
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(1);
              irregularThresholdVal = (item['threshold']).toFixed(1);
            }
            break;

          case 'waterLevel':
          case 'water_level':
            dataUnit = "(m)";
            if(lang == "jp"){
              sensorName = "水位";
            }
            else if(lang == "en"){
              sensorName = "Waterlevel";
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(3);
              irregularThresholdVal = (item['threshold']).toFixed(3);
            }
            break;

          // 流量
          case 'waterQuantity':
          case 'water_quantity':
            dataUnit = "(㎥/sec)";
            if(lang == "jp"){
              sensorName = "流量";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(3);
              irregularThresholdVal = (item['threshold']).toFixed(3);
            }
            break;

          // 海水電気伝導度
          case 'saltwater_conductivity':
          case 'seawaterConductivity':
            dataUnit = "(μS/cm)";
            if(lang == "jp"){
              sensorName = "海水電気伝導度";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(1);
              irregularThresholdVal = (item['threshold']).toFixed(1);
            }
            break;

          // 淡水電気伝導度
          case 'freshwaterConductivity':
          case 'freshwater_conductivity':
            dataUnit = "(mS/cm)";
            if(lang == "jp"){
              sensorName = "淡水電気伝導度";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(2);
              irregularThresholdVal = (item['threshold']).toFixed(2);
            }
            break;

          // 水温
          case 'waterTemperature':
          case 'water_temprature':
            dataUnit = "(℃)";
            if(lang == "jp"){
              sensorName = "水温";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(2);
              irregularThresholdVal = (item['threshold']).toFixed(2);
            }
            break;

          // バッテリー電圧
          case 'batteryVoltage':
          case 'battery_voltage':
            dataUnit = "(V)";
            if(lang == "jp"){
              sensorName = "バッテリー電圧";
            }
            else if(lang == "en"){
              sensorName = item["dataNameToDisplay"];
            }

            if(item['value'] != null){
              irregularVal = (item['value']).toFixed(3);
              irregularThresholdVal = (item['threshold']).toFixed(3);
            }
            break;

          default:
              if(item['value'] != null){
              irregularVal = item['value'];
              irregularThresholdVal = item['threshold'];
            }
              break;
        }

        const alertInfo: MachineIrregularData = {
          Datetime: displayedDate,
          City: isNull(item["cityName"]),
          Area: isNull(item["areaName"]),
          Point: isNull(item["sensorName"]),
          Sensor: sensorName,
          Unit: isNull(dataUnit),
          ThresholdValue: isNull(irregularThresholdVal),
          Value: isNull(irregularVal),
          Detail: detailMessage
        }

        //this.machineIrregularDataList.push(alertInfo);
        csvOutputDataList.push(alertInfo);
      }

      //存在チェック
      if(csvOutputDataList.length == 0){
        return;
      }

      //日時でソートする
      csvOutputDataList.sort(function (a, b) {
        // if (a.Point !== b.Point) {
        //   return a.Point < b.Point ? -1 : 1;
        // }
        // return (a.Datetime < b.Datetime) ? -1 : 1;
        if (a.Datetime !== b.Datetime) {
          return a.Datetime < b.Datetime ? -1 : 1;
        }
        return (a.Point < b.Point) ? -1 : 1;
      });

      // ソート後ヘッダーを入れる
      if(lang == "jp"){
        const headerItem: MachineIrregularData = {
            Datetime: "日時",
            City: "都市",
            Area: "地区",
            Point: "地点",
            Sensor: "センサー",
            Unit: "単位",
            ThresholdValue: "閾値",
            Value: "測定値",
            Detail: "詳細"
          }
          csvOutputDataList.unshift(headerItem);
      }
      else if(lang == "en")
      {
        const headerItem: MachineIrregularData = {
            Datetime: "Datetime",
            City: "City",
            Area: "Area",
            Point: "Point",
            Sensor: "Sensor",
            Unit: "Unit",
            ThresholdValue: "ThresholdValue",
            Value: "Value",
            Detail: "Detail"
          }
          csvOutputDataList.unshift(headerItem);
      }

      const csvExporter = new ExportToCsv(this.options);
      csvExporter.generateCsv(csvOutputDataList);
    })
  }

  //機器異常をcsv出力する(新)
  // makeIrregularCsv(fromDate: string, toDate: string): void{
  //   let listSendData = {};
  //   let irregularSendData = {};
  //   let csvOutputDataList: any[] = [];
  //   let lang = sessionStorage.getItem("lang");
  //   let irregularThresholdVal: any;
  //   let irregularVal: any;
  //   let sensorName: string;
  //   let detailMessage: string;
  //   const today = new Date();
  //   let isDateFilter: boolean = false; //日付選択のフラグ
  //   let count: number = 0;
  //   let returnValue: any[] = [];

  //   if(lang == null || lang == "" || lang == undefined){
  //     lang = this.cookieService.get('language');
  //   }

  //   //日付が指定されているか判定する(FromTo両方指定されていなければデフォルトの日付)
  //   if(fromDate !== "" && fromDate !== undefined && fromDate !== null){

  //     if(toDate !== "" && toDate !== undefined && toDate !== null){
  //       isDateFilter = true;

  //       const sendData = {
  //         cityId: this.currentCityId,
  //         fromDate: moment(new Date(fromDate)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(toDate)).format('YYYY-MM-DD')
  //       }
  //       this.csvAlertRequest = sendData;
  //     }
  //     else{
  //       isDateFilter = false;

  //       const sendData = {
  //         cityId: this.currentCityId,
  //         fromDate: moment(new Date(today)).format('YYYY-MM-DD'),
  //         toDate: moment(new Date(today)).format('YYYY-MM-DD')
  //       };
  //       this.csvAlertRequest = sendData;
  //     }
  //   }
  //   else{
  //     isDateFilter = false;

  //     const sendData = {
  //       cityId: this.currentCityId,
  //       fromDate: moment(new Date(today)).format('YYYY-MM-DD'),
  //       toDate: moment(new Date(today)).format('YYYY-MM-DD')
  //     };
  //     this.csvAlertRequest = sendData;
  //   }

  //   this.todoService.getAlertsByCityIdAndDate(this.csvAlertRequest).subscribe((alertResponse: any) => {
  //     let alertsArray = alertResponse;

  //     if(alertsArray.length != 0){

  //       if(isDateFilter == true){

  //         //日付指定をしている場合はAlertsテーブルに計測データが入っていない場合があるため二回リクエストをおこなう
  //         this.todoService.getAllByCityIdAndMultipleDates(this.csvAlertRequest).subscribe((valueResponse: any) => {
  //           let dataArray = valueResponse;

  //           for(let index = 0; index < alertsArray.length; index++){
  //             let alertArray = alertsArray[index].alertResponses;

  //             if(alertArray.length != 0){
  //               let selectedThreshold = this.allThresholdValueResponse.filter((x: any) => x[0].mainSensorId == alertArray[0].mainSensorId);
  //               let selectedValueArray = valueResponse.find((x: any) => x.waterFlowResponse[0].mainSensorId == alertArray[0].mainSensorId);

  //               if(selectedThreshold.length != 0){

  //                 for(let subIndex = 0; subIndex < alertArray.length; subIndex++){
  //                   let date = new Date(alertArray[subIndex].dataTimeStamp * 1000);
  //                   let displayedDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

  //                   let lowerAlertVal: number = 0; //下限値
  //                   let upperAlertVal: number = 0; //上限値

  //                   if(selectedValueArray != undefined){
  //                     let selectedData = selectedValueArray.waterFlowResponse.find((x: any) => x.timeStamp == alertArray[subIndex].dataTimeStamp);

  //                       if(selectedData != undefined){

  //                         switch (alertArray[subIndex]['dataName']) {
  //                           case 'turbidity':
  //                             returnValue = check(alertArray[subIndex]['dataName'], 'S002', 1, "(FTU)", alertArray[subIndex], selectedData, lang, selectedThreshold);

  //                             break;
  //                           case 'water_level':
  //                             returnValue = check(alertArray[subIndex]['dataName'], 'S001', 3, "(m)", alertArray[subIndex], selectedData, lang, selectedThreshold);

  //                             break;
  //                           case 'water_quantity':
  //                             returnValue = check(alertArray[subIndex]['dataName'], 'S005', 3, "(㎥/sec)", alertArray[subIndex], selectedData, lang, selectedThreshold);

  //                             break;
  //                           case 'freshwater_conductivity':
  //                             returnValue = check(alertArray[subIndex]['dataName'], 'S004', 1, "(μS/cm)", alertArray[subIndex], selectedData, lang, selectedThreshold);

  //                             break;
  //                           case 'saltwater_conductivity':
  //                             returnValue = check(alertArray[subIndex]['dataName'], 'S006', 2, "(mS/cm)", alertArray[subIndex], selectedData, lang, selectedThreshold);

  //                             break;
  //                           case 'water_temprature':
  //                             returnValue = check(alertArray[subIndex]['dataName'], 'S007', 2, "(℃)", alertArray[subIndex], selectedData, lang, selectedThreshold);

  //                             break;
  //                           case 'battery_voltage':
  //                             returnValue = check(alertArray[subIndex]['dataName'], 'S003', 2, "(V)", alertArray[subIndex], selectedData, lang, selectedThreshold);

  //                             break;

  //                           default:
  //                             break;
  //                         }

  //                         const alertInfo: MachineIrregularData = {
  //                           Datetime: displayedDate,
  //                           City: selectedData['cityName'],
  //                           Area: selectedData['areaName'],
  //                           Point: selectedData['sensorName'],
  //                           Sensor: returnValue[0],
  //                           Unit: returnValue[1],
  //                           ThresholdValue: returnValue[3],
  //                           Value: returnValue[4],
  //                           Detail: returnValue[2]
  //                         }
  //                         csvOutputDataList.push(alertInfo);
  //                       }
  //                   }
  //                   else{
  //                     //あとで
  //                   }
  //                 }
  //                 //end for
  //               }
  //               else{
  //                 //あとで
  //               }
  //             }
  //             else{
  //               count++;
  //             }
  //           }
  //           //end for

  //           //日時でソートする
  //           csvOutputDataList.sort(function (a, b) {
  //             if (a.Point !== b.Point) {
  //               return a.Point < b.Point ? -1 : 1;
  //             }
  //             return (a.Datetime < b.Datetime) ? -1 : 1;
  //           });

  //           //ヘッダーを入れる
  //           if(lang == "jp"){

  //             const headerItem: MachineIrregularData = {
  //               Datetime: "日時",
  //               City: "都市",
  //               Area: "地区",
  //               Point: "地点",
  //               Sensor: "センサー",
  //               Unit: "単位",
  //               ThresholdValue: "閾値",
  //               Value: "測定値",
  //               Detail: "詳細"
  //             }
  //             csvOutputDataList.unshift(headerItem);
  //           }
  //           else if(lang == "en"){

  //             const headerItem: MachineIrregularData = {
  //               Datetime: "Datetime",
  //               City: "City",
  //               Area: "Area",
  //               Point: "Point",
  //               Sensor: "Sensor",
  //               Unit: "Unit",
  //               ThresholdValue: "ThresholdValue",
  //               Value: "Value",
  //               Detail: "Detail"
  //             }
  //             csvOutputDataList.unshift(headerItem);
  //           }

  //           const csvExporter = new ExportToCsv(this.options);
  //           csvExporter.generateCsv(csvOutputDataList);
  //         })
  //       }
  //       else{

  //         //日付指定をしていない場合はAlertsテーブルに計測データも入っているためリクエストは一回だけ
  //         for(let index = 0; index < alertsArray.length; index++){
  //           let alertArray = alertsArray[index].alertResponses;

  //           if(alertArray.length != 0){

  //             for(let subIndex = 0; subIndex < alertArray.length; subIndex++){
  //               let date = new Date(alertArray[subIndex].dataTimeStamp * 1000);
  //               let displayedDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm");
  //             }
  //           }
  //         }
  //       }
  //     }
  //     else{
  //       //「データはありません」等の表示をおこなう
  //     }
  //   })
  // }

  //閾値超過をcsv出力する
  csvThresholdOutput(): void{

    this.todoService.getThresholdValuesByCityId(this.currentCityId).subscribe((response: any) => {
      const cityThresholdData = _.cloneDeep(response);

      let csvOutputDataList: any[] = [];

      //現在使用中の言語を取得(今後の対応のため)
      //const currrentLang = sessionStorage.getItem('lang');

      for(let index = 0; index < cityThresholdData.length; index++){
        const wlal = cityThresholdData[index].filter((x: any) => x.dataPointName == "S001");//水位
        const wqal = cityThresholdData[index].filter((x: any) => x.dataPointName == "S005");//流量
        const pickedMainSensorId = cityThresholdData[index][0]['mainSensorId'];

        //データソースからメインセンサーIDが等しいものを抜き出す
        for(let a = 0; a < this.csvResponseData.length; a++){
          const searchedData = this.csvResponseData[a].waterFlowResponse.filter((x: any) => x.mainSensorId == pickedMainSensorId);

          if(searchedData.length !== 0){
            this.csvPointData = searchedData;
          }
        }

        if(wlal.length > 0 || wqal.length > 0){
          const csvWaterLevelAlertLevel1 = wlal[0].alertLevel1;
          const csvWaterLevelAlertLevel2 = wlal[0].alertLevel2;
          const csvWaterLevelAlertLevel3 = wlal[0].alertLevel3;

          const csvWaterQuantityAlertLevel1 = wqal[0].alertLevel1;
          const csvWaterQuantityAlertLevel2 = wqal[0].alertLevel2;
          const csvWaterQuantityAlertLevel3 = wqal[0].alertLevel3;

          //閾値超過をチェック
          for(let i = 0; i < this.csvPointData.length; i++){

            //小数点の調整と数値型への変更(水位)
            if(this.csvPointData[i]['waterLevel'] !== null){
              this.csvPointData[i]['waterLevel'] = parseFloat(this.csvPointData[i]['waterLevel']).toFixed(3);
            }
            if(this.csvPointData[i]['rawWaterLevel'] !== null){
              this.csvPointData[i]['rawWaterLevel'] = parseFloat(this.csvPointData[i]['rawWaterLevel']).toFixed(3);
            }

            //小数点の調整と数値型への変更(流量)
            if(this.csvPointData[i]['waterQuantity'] !== null){
              this.csvPointData[i]['waterQuantity'] = parseFloat(this.csvPointData[i]['waterQuantity']).toFixed(3);
            }
            if(this.csvPointData[i]['rawWaterQuantity'] !== null){
              this.csvPointData[i]['rawWaterQuantity'] = parseFloat(this.csvPointData[i]['rawWaterQuantity']).toFixed(3);
            }

            //水位警戒レベル3以上を抽出
            if(this.csvPointData[i]['waterLevel'] >= csvWaterLevelAlertLevel3){

              const csvOutputData: ExtraThresholdData = {
                Datetime: this.csvPointData[i]['dateTime'],
                City: this.csvPointData[i]['cityName'],
                Area: this.csvPointData[i]['areaName'],
                Point: this.csvPointData[i]['sensorName'],
                Sensor: "waterLevel",
                Unit: "(m)",
                AlertLevel: 3,
                ThresholdValue: csvWaterLevelAlertLevel3,
                Value: parseFloat(this.csvPointData[i]['waterLevel']),
              }
              csvOutputDataList.push(csvOutputData);
            }

            //水位警戒レベル2以上3未満を抽出
            if(this.csvPointData[i]['waterLevel'] >= csvWaterLevelAlertLevel2
            && this.csvPointData[i]['waterLevel'] < csvWaterLevelAlertLevel3){

              const csvOutputData: ExtraThresholdData = {
                Datetime: this.csvPointData[i]['dateTime'],
                City: this.csvPointData[i]['cityName'],
                Area: this.csvPointData[i]['areaName'],
                Point: this.csvPointData[i]['sensorName'],
                Sensor: "waterLevel",
                Unit: "(m)",
                AlertLevel: 2,
                ThresholdValue: csvWaterLevelAlertLevel2,
                Value: parseFloat(this.csvPointData[i]['waterLevel']),
              }
              csvOutputDataList.push(csvOutputData);
            }

            //水位警戒レベル1以上2未満を抽出
            if(this.csvPointData[i]['waterLevel'] >= csvWaterLevelAlertLevel1
            && this.csvPointData[i]['waterLevel'] < csvWaterLevelAlertLevel2){

              const csvOutputData: ExtraThresholdData = {
                Datetime: this.csvPointData[i]['dateTime'],
                City: this.csvPointData[i]['cityName'],
                Area: this.csvPointData[i]['areaName'],
                Point: this.csvPointData[i]['sensorName'],
                Sensor: "waterLevel",
                Unit: "(m)",
                AlertLevel: 1,
                ThresholdValue: csvWaterLevelAlertLevel1,
                Value: parseFloat(this.csvPointData[i]['waterLevel']),
              }
              csvOutputDataList.push(csvOutputData);
            }

            //流量警戒レベル3以上を抽出
            if(this.csvPointData[i]['waterQuantity'] >= csvWaterQuantityAlertLevel3){

              const csvOutputData: ExtraThresholdData = {
                Datetime: this.csvPointData[i]['dateTime'],
                City: this.csvPointData[i]['cityName'],
                Area: this.csvPointData[i]['areaName'],
                Point: this.csvPointData[i]['sensorName'],
                Sensor: "waterQuantity",
                Unit: "(㎥/sec)",
                AlertLevel: 3,
                ThresholdValue: csvWaterQuantityAlertLevel3,
                Value: parseFloat(this.csvPointData[i]['waterQuantity']),
              }
              csvOutputDataList.push(csvOutputData);
            }

            //流量警戒レベル2以上3未満を抽出
            if(this.csvPointData[i]['waterQuantity'] >= csvWaterQuantityAlertLevel2
            && this.csvPointData[i]['waterQuantity'] < csvWaterQuantityAlertLevel3){

              const csvOutputData: ExtraThresholdData = {
                Datetime: this.csvPointData[i]['dateTime'],
                City: this.csvPointData[i]['cityName'],
                Area: this.csvPointData[i]['areaName'],
                Point: this.csvPointData[i]['sensorName'],
                Sensor: "waterQuantity",
                Unit: "(㎥/sec)",
                AlertLevel: 2,
                ThresholdValue: csvWaterQuantityAlertLevel2,
                Value: parseFloat(this.csvPointData[i]['waterQuantity']),
              }
              csvOutputDataList.push(csvOutputData);
            }

            //流量警戒レベル1以上2未満を抽出
            if(this.csvPointData[i]['waterQuantity'] >= csvWaterQuantityAlertLevel1
            && this.csvPointData[i]['waterQuantity'] < csvWaterQuantityAlertLevel2){

              const csvOutputData: ExtraThresholdData = {
                Datetime: this.csvPointData[i]['dateTime'],
                City: this.csvPointData[i]['cityName'],
                Area: this.csvPointData[i]['areaName'],
                Point: this.csvPointData[i]['sensorName'],
                Sensor: "waterQuantity",
                Unit: "(㎥/sec)",
                AlertLevel: 1,
                ThresholdValue: csvWaterQuantityAlertLevel1,
                Value: parseFloat(this.csvPointData[i]['waterQuantity']),
              }
              csvOutputDataList.push(csvOutputData);
            }
          }
        }
      }
      csvOutputDataList.splice(csvOutputDataList.length - 1, 1);

      // 日付、地点でソート
      csvOutputDataList.sort(function (a, b) {
        // if (a.Point !== b.Point) {
        //   return a.Point < b.Point ? -1 : 1;
        // }
        // return (a.Datetime < b.Datetime) ? -1 : 1;
        if (a.Datetime !== b.Datetime) {
          return a.Datetime < b.Datetime ? -1 : 1;
        }
        return (a.Point < b.Point) ? -1 : 1;
      })

      const csvExporter = new ExportToCsv(this.options);
      csvExporter.generateCsv(csvOutputDataList);
      this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
    })
  }

  //機器異常をcsv出力する
//   csvMachineIrregularOutout(isDateSelected: boolean): void{

//     //日付が選択されている場合
//     if(isDateSelected == true){
//       const fd = (document.getElementById(`csvdt1`) as HTMLInputElement).value;
//       const td = (document.getElementById(`csvdt2`) as HTMLInputElement).value;

//       const sendData = {
//         cityId: this.currentCityId,
//         fromDate: moment(new Date(fd)).format('YYYY-MM-DD'),
//         toDate: moment(new Date(td)).format('YYYY-MM-DD')
//       }
//       this.csvAlertRequest = sendData;
//     }

//     //日付が選択されていない場合
//     else{
//       const today = new Date();

//       const sendData = {
//         cityId: this.currentCityId,
//         fromDate: moment(new Date(today)).subtract(30, 'h').format("YYYY-MM-DD"),
//         toDate: moment(new Date(today)).add(1, 'd').format("YYYY-MM-DD"),
//       }
//       this.csvAlertRequest = sendData;
//     }

//     this.todoService.getAlertsByCityIdAndDate(this.csvAlertRequest).subscribe((response: any) => {
//       let csvAlertResponse = _.cloneDeep(response);
//       let csvOutputDataList: any[] = [];

//       for(let totalIndex = 0; totalIndex < csvAlertResponse.length; totalIndex++){

//         if(csvAlertResponse[totalIndex].alertResponses.length != 0){
//           const pointAlertArray = csvAlertResponse[totalIndex].alertResponses;

//           for(let index = 0; index < pointAlertArray.length; index++){
//             //タイムスタンプを日付に変更
//             let date = new Date(pointAlertArray[index].dataTimeStamp * 1000);
//             let displayedDate = moment(new Date(date)).format("YYYY-MM-DD HH:mm");

//             let lowerAlertVal = 0; //下限値
//             let upperAlertVal = 0; //上限値

//             //メインセンサーIDがアラートと等しいデータのみ抽出
//             for(let a = 0; a < this.csvResponseData.length; a++){
//               const pickedData = this.csvResponseData[a].waterFlowResponse
//               .filter((x: any) => x.mainSensorId == pointAlertArray[0].mainSensorId);

//               let selectedThreshold = this.allThresholdValueResponse.filter((x: any) => x[0].mainSensorId == pointAlertArray[0].mainSensorId);

//               if(pickedData.length >= 2){
//                 //this.pickedPointData = pickchedData;

//                 for(let selectedDataIndex = 0; selectedDataIndex < pickedData.length - 1; selectedDataIndex++){

//                   if (pickedData[selectedDataIndex].dateTime == displayedDate) {
//                     let forUnit = "";

//                     //以下、閾値と測定値を取り出す
//                     //濁度のチェック
//                     if(pointAlertArray[index]['dataName'] == 'turbidity'){

//                       if(selectedThreshold[0].length > 0) {
//                         const sensor = selectedThreshold[0].filter((x: any) => x.dataPointName == 'S002');

//                         if(sensor.length > 0){
//                           lowerAlertVal = sensor[0].lowerLowerLimit;
//                           upperAlertVal = sensor[0].upperUpperLimit;
//                         }
//                       }

//                       if(pointAlertArray[index]['status'] == "Upper value  exceeded") this.irregularThresholdValue = upperAlertVal;
//                       else this.irregularThresholdValue =  lowerAlertVal;

//                       if (stringOrNot(pickedData[selectedDataIndex]['turbidity'])) {
//                         this.irregularValue = (parseFloat(pickedData[selectedDataIndex]['turbidity'])).toFixed(1);
//                         let rawVal = parseFloat((parseFloat(pickedData[selectedDataIndex]['rawTurbidity'])).toFixed(1));

//                         if (rawVal <= 100) {
//                           pointAlertArray[index]['status'] = "Battery dead";
//                         }
//                       }
//                       else {
//                         this.irregularValue = "-";
//                       }

//                       forUnit = "(FTU)";
//                     }

//                     //水位のチェック
//                     else if(pointAlertArray[index]['dataName'] == 'water_level'){

//                       if(selectedThreshold[0].length > 0) {
//                         const sensor = selectedThreshold[0].filter((x: any) => x.dataPointName == 'S001');

//                         if(sensor.length > 0){
//                           lowerAlertVal = sensor[0].lowerLowerLimit;
//                           upperAlertVal = sensor[0].upperUpperLimit;
//                         }
//                       }

//                       if(pointAlertArray[index]['status'] == "Upper value  exceeded") this.irregularThresholdValue = upperAlertVal;
//                       else this.irregularThresholdValue =  lowerAlertVal;

//                       if (stringOrNot(pickedData[selectedDataIndex]['waterLevel'])) {
//                         this.irregularValue = (parseFloat(pickedData[selectedDataIndex]['waterLevel'])).toFixed(3);
//                       }
//                       else {
//                         this.irregularValue = "-";
//                       }

//                       forUnit = "(m)";
//                     }

//                     //流量のチェック
//                     else if(pointAlertArray[index]['dataName'] == 'water_quantity'){

//                       if(selectedThreshold[0].length > 0) {
//                         const sensor = selectedThreshold[0].filter((x: any) => x.dataPointName == 'S005');

//                         if(sensor.length > 0){
//                           lowerAlertVal = sensor[0].lowerLowerLimit;
//                           upperAlertVal = sensor[0].upperUpperLimit;
//                         }
//                       }

//                       if(pointAlertArray[index]['status'] == "Upper value  exceeded") this.irregularThresholdValue = upperAlertVal;
//                       else this.irregularThresholdValue =  lowerAlertVal;

//                       if (stringOrNot(pickedData[selectedDataIndex]['waterQuantity'])
//                       && stringOrNot(pickedData[selectedDataIndex]['rawWaterQuantity'])) {
//                         this.irregularValue = (parseFloat(pickedData[selectedDataIndex]['waterQuantity'])).toFixed(3);
//                         let rawVal = parseFloat((parseFloat(pickedData[selectedDataIndex]['rawWaterQuantity'])).toFixed(3));

//                         if (rawVal <= 100) {
//                           pointAlertArray[index]['status'] = "Battery dead";
//                         }
//                       }
//                       else {
//                         this.irregularValue = "-";
//                       }

//                       forUnit = "(㎥/sec)";
//                     }

//                     //淡水電気伝導度のチェック
//                     else if(pointAlertArray[index]['dataName'] == 'freshwater_conductivity'){

//                       if(selectedThreshold[0].length > 0) {
//                         const sensor = selectedThreshold[0].filter((x: any) => x.dataPointName == 'S004');

//                         if(sensor.length > 0){
//                           lowerAlertVal = sensor[0].lowerLowerLimit;
//                           upperAlertVal = sensor[0].upperUpperLimit;
//                         }
//                       }

//                       if(pointAlertArray[index] == "Upper value  exceeded") this.irregularThresholdValue = upperAlertVal;
//                       else this.irregularThresholdValue =  lowerAlertVal;

//                       if (stringOrNot(pickedData[selectedDataIndex]['freshwaterConductivity'])) {
//                         this.irregularValue = (parseFloat(pickedData[selectedDataIndex]['freshwaterConductivity'])).toFixed(1);
//                       }
//                       else {
//                         this.irregularValue = "-";
//                       }

//                       forUnit = "(μS/cm)";
//                     }

//                     //海水電気伝導度のチェック
//                     else if(pointAlertArray[index]['dataName'] == 'saltwater_conductivity'){

//                       if(selectedThreshold[0].length > 0) {
//                         const sensor = selectedThreshold[0].filter((x: any) => x.dataPointName == 'S006');

//                         if(sensor.length > 0){
//                           lowerAlertVal = sensor[0].lowerLowerLimit;
//                           upperAlertVal = sensor[0].upperUpperLimit;
//                         }
//                       }

//                       if(pointAlertArray[index]['status'] == "Upper value  exceeded") this.irregularThresholdValue = upperAlertVal;
//                       else this.irregularThresholdValue =  lowerAlertVal;

//                       if (stringOrNot(pickedData[selectedDataIndex]['saltwaterConductivity'])) {
//                         this.irregularValue = (parseFloat(pickedData[selectedDataIndex]['saltwaterConductivity'])).toFixed(2);
//                       }
//                       else {
//                         this.irregularValue = "-";
//                       }

//                       forUnit = "(mS/cm)";
//                     }

//                     //水温のチェック
//                     else if(pointAlertArray[index]['dataName'] == 'water_temprature'){

//                       if(selectedThreshold[0].length > 0) {
//                         const sensor = selectedThreshold[0].filter((x: any) => x.dataPointName == 'S007');

//                         if(sensor.length > 0){
//                           lowerAlertVal = sensor[0].lowerLowerLimit;
//                           upperAlertVal = sensor[0].upperUpperLimit;
//                         }
//                       }

//                       if(pointAlertArray[index] == "Upper value  exceeded") this.irregularThresholdValue = upperAlertVal;
//                       else this.irregularThresholdValue =  lowerAlertVal;

//                       if (stringOrNot(pickedData[selectedDataIndex]['waterTemprature'])) {
//                         this.irregularValue = (parseFloat(pickedData[selectedDataIndex]['waterTemprature'])).toFixed(2);
//                       }
//                       else {
//                         this.irregularValue = "-";
//                       }

//                       forUnit = "(℃)";
//                     }

//                     //バッテリー電圧のチェック
//                     else if(pointAlertArray[index]['dataName'] == 'battery_voltage'){

//                       if(selectedThreshold[0].length > 0) {
//                         const sensor = selectedThreshold[0].filter((x: any) => x.dataPointName == 'S003');

//                         if(sensor.length > 0){
//                           lowerAlertVal = sensor[0].lowerLowerLimit;
//                           upperAlertVal = sensor[0].upperUpperLimit;
//                         }
//                       }

//                       if(pointAlertArray[index]['status'] == "Upper value  exceeded") this.irregularThresholdValue = upperAlertVal;
//                       else this.irregularThresholdValue =  lowerAlertVal;

//                       if (stringOrNot(pickedData[selectedDataIndex]['batteryVoltage'])) {
//                         this.irregularValue = (parseFloat(pickedData[selectedDataIndex]['batteryVoltage'])).toFixed(3);
//                       }
//                       else {
//                         this.irregularValue = "-";
//                       }

//                       forUnit = "(V)";
//                     }

//                     else if(pointAlertArray[index]['dataName'] == 'timestamp'){
//                       this.irregularThresholdValue = "";
//                       this.irregularValue = "";
//                     }
//                     else if(pointAlertArray[index]['dataName'] == 'ch1'){
//                       this.irregularThresholdValue = "";
//                       this.irregularValue = "";
//                     }
//                     else if(pointAlertArray[index]['dataName'] == 'ch2'){
//                       this.irregularThresholdValue = "";
//                       this.irregularValue = "";
//                     }
//                     else if(pointAlertArray[index]['dataName'] == 'ch3'){
//                       this.irregularThresholdValue = "";
//                       this.irregularValue = "";
//                     }
//                     else if(pointAlertArray[index]['dataName'] == 'ch4'){
//                       this.irregularThresholdValue = "";
//                       this.irregularValue = "";
//                     }
//                     else if(pointAlertArray[index]['dataName'] == 'adc0'){
//                       this.irregularThresholdValue = "";
//                       this.irregularValue = "";
//                     }
//                     else if(pointAlertArray[index]['dataName'] == 'adc1'){
//                       this.irregularThresholdValue = "";
//                       this.irregularValue = "";
//                     }
//                     else{
//                       this.irregularThresholdValue = "err";
//                       this.irregularValue = "err";
//                     }

//                     //  if(this.irregularThresholdValue !== null){
//                     //    this.irregularThresholdValue = parseFloat(this.irregularThresholdValue).toFixed(3);
//                     //   }
//                     //  if(this.irregularRawValue !== null){
//                     //    this.irregularRawValue = parseFloat(this.irregularRawValue).toFixed(3);
//                     //   }

//                     const alertInfo: MachineIrregularData = {
//                       Datetime: pickedData[selectedDataIndex]['dateTime'],
//                       City: pickedData[selectedDataIndex]['cityName'],
//                       Area: pickedData[selectedDataIndex]['areaName'],
//                       Point: pickedData[selectedDataIndex]['sensorName'],
//                       Sensor: pointAlertArray[index]['dataNameToDisplay'],
//                       Unit: forUnit,
//                       ThresholdValue: parseFloat(this.irregularThresholdValue),
//                       Value: parseFloat(this.irregularValue),
//                       Detail: pointAlertArray[index]['status'],
//                     }
//                     csvOutputDataList.push(alertInfo);
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//       csvOutputDataList.splice(csvOutputDataList.length - 1, 1);

//       // 日付、地点でソート
//       csvOutputDataList.sort(function (a, b) {
//         if (a.Datetime !== b.Datetime) {
//           return a.Datetime < b.Datetime ? -1 : 1;
//         }
//         return (a.Point < b.Point) ? -1 : 1;
//       })

//       const csvExporter = new ExportToCsv(this.options);
//       csvExporter.generateCsv(csvOutputDataList);
//       this.snotifyService.success(this.translate.instant('Data exported successfully'), '');
//     })
//   }

}

function stringOrNot(value: any) : boolean {

  if (typeof value === "string" || value instanceof String || typeof value === "number" || value instanceof Number) {
    return true;
  }
  else {
    return false;
  }
}

function isNull(value: any): any
{
  if (typeof value !== "string" && (null == value || value == "NaN" || isNaN(value))) {
    return "-";
  }
  else
  {
    return value == "" ? "-" : value;
  }
}

//dataName = データ種別
//id = SensorTypeId
//digit = 小数点以下の有効桁数
//unit = 単位
//alertData = 「Alerts」テーブルのデータ
//data = 「WaterFlowDetails」テーブルのデータ
//lang = 現在選択中の言語
function check(dataName: string, id: string, digit: number, unit: string, alertData: any, data: any, lang: any, selectedThreshold: any): any{
  let message: string = "";
  let lowerAlertVal = 0;
  let upperAlertVal = 0;
  let limit = 0;
  let value: any;
  let arrayDataName: string = "";

  if(selectedThreshold[0].length > 0) {
    const sensor = selectedThreshold[0].find((x: any) => x.dataPointName == id);

    if(sensor != undefined){
      lowerAlertVal = sensor.lowerLowerLimit;
      upperAlertVal = sensor.upperUpperLimit;
    }
  }

  if(alertData['status'] == "Upper value  exceeded"){
    limit = upperAlertVal;
    message = lang == "jp"? "上限値を超過しています" : "Upper value  exceeded";
  }
  else if(alertData['status'] == "Lower value  exceeded"){
    limit =  lowerAlertVal;
    message = lang == "jp"? "下限値を超過しています" : "Lower value  exceeded";
  }
  else if(alertData['status'] == "Battery dead"){
    limit =  100;
    message = lang == "jp"? "電池切れ" : "Battery dead";
  }
  else{}

  switch (alertData['dataName']) {
    case 'turbidity':
      dataName = lang == "jp"? "濁度" : "Turbidity";
      arrayDataName = "turbidity";

      break;
    case 'water_level':
      dataName = lang == "jp"? "水位" : "Waterlevel";
      arrayDataName = "waterLevel";

      break;
    case 'water_quantity':
      dataName = lang == "jp"? "流量" : "Waterquantity";
      arrayDataName = "waterQuantity";

      break;
    case 'freshwater_conductivity':
      dataName = lang == "jp"? "淡水電気伝導度" : "Freshwaterconductivity";
      arrayDataName = "freshwaterConductivity";

      break;
    case 'saltwater_conductivity':
      dataName = lang == "jp"? "海水電気伝導度" : "Seawaterconductivity";
      arrayDataName = "saltwaterConductivity";

      break;
    case 'water_temprature':
      dataName = lang == "jp"? "水温" : "Watertemperature";
      arrayDataName = "waterTemprature";

      break;
    case 'battery_voltage':
      dataName = lang == "jp"? "バッテリー電圧" : "Batteryvoltage";
      arrayDataName = "batteryVoltage";

      break;

    default:
      break;
  }

  if (stringOrNot(data[arrayDataName])) {
    value = parseFloat((parseFloat(data[arrayDataName])).toFixed(digit));
  }
  else {
    value = "-";
  }

  return [dataName, unit, message, limit, value];
}
